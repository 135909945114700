/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-09-2024
 * @description : To request co-owner/editor/viewer access to the project/zones/ee component/software module
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  HandleApiActions,
  labCar,
  userManagementApis,
} from "../../../redux/actions";
import customConsole from "../../../config/customConsole";
import {
  Paper,
  ButtonGroup,
  Button,
  Typography,
  Divider,
  Grid,
  Box,
} from "@mui/material";
import {
  CustomButton,
  CustomTextFieldN,
  EEDetails,
  ModuleAccessTypes,
  ProjectDetails,
  SearchSelectTextField,
  SelectModules,
  SWMDetails,
} from "../../../components";
import { customAppColors } from "../../../assets/app-theme/appColors";

function NewRequestTab({
  selectedProjectDetails,
  projectStatus,
  moduleTypes,
  accessTypes,
}) {
  const dispatch = useDispatch();

  // New Requests Buttons
  const [selectedPaBtn, setSelectedPaBtn] = useState(moduleTypes?.ZONE);

  // Co-owners state
  // const [coOwnersOptionsList, setCoOwnersOptionsList] = useState([]);
  // const [selectedCoOwner, setSelectedCoOwner] = useState(null);

  // Access type
  const [selectedAtBtn, setSelectedAtBtn] = useState(accessTypes?.COOWNER);

  // Zones States
  const [zoneOptionsList, setZoneOptionsList] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedZoneDetails, setSelectedZoneDetails] = useState(null);

  // EE Components States
  const [zoneEEDetails, setZoneEEDetails] = useState([]);
  const [eeOptionsList, setEEOptionsList] = useState([]);
  const [selectedEE, setSelectedEE] = useState(null);
  const [selectedEEDetails, setSelectedEEDetails] = useState(null);

  // Software Modules States
  const [eeSwmDetails, setEESwmDetails] = useState([]);
  const [swmOptionsList, setSWMOptionsList] = useState([]);
  const [selectedSWM, setSelectedSWM] = useState(null);
  const [selectedSWMDetails, setSelectedSWMDetails] = useState(null);

  // Reason for access text field state
  const [reasonForAccess, setReasonForAccess] = useState("");

  // Functions to handle data //
  // Get EE Components Details
  const getZoneEEDetails = async ({ projectId, zoneSequence }) => {
    let apiDetails = labCar.get_ee_components_pa;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        ...(zoneSequence && { zone_sequence: zoneSequence }),
        is_for_req_access: true,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          ...(zoneSequence && { zone_sequence: zoneSequence }),
          is_for_req_access: true,
        },
      })
    );

    if (resp.error) {
      setZoneEEDetails([]);
    } else {
      setZoneEEDetails(resp.data);
    }
  };

  // Get Software Module Details
  const getEESwmDetails = async ({ projectId, eeId }) => {
    let apiDetails = labCar.get_sw_modules_pa;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        ee_id: eeId,
        is_for_req_access: true,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          ee_id: eeId,
          is_for_req_access: true,
        },
      })
    );

    if (resp.error) {
      setEESwmDetails([]);
    } else {
      setEESwmDetails(resp.data);
    }
  };

  // Request access
  const requestAccess = async ({
    accessType,
    moduleType,
    projectId,
    eeId,
    swmId,
    zonePosition,
    requestReason,
  }) => {
    let apiDetails = userManagementApis.request_access;

    customConsole({
      ...apiDetails,
      params: {
        access_type: accessType,
        module_type: moduleType,
        project_id: Number(projectId),
        ...(eeId && { ee_id: Number(eeId) }),
        ...(swmId && { swm_id: Number(swmId) }),
        ...(zonePosition && { zone_position: zonePosition }),
        request_reason: requestReason,
      },
      show_toast: true,
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          access_type: accessType,
          module_type: moduleType,
          project_id: Number(projectId),
          ...(eeId && { ee_id: Number(eeId) }),
          ...(swmId && { swm_id: Number(swmId) }),
          ...(zonePosition && { zone_position: zonePosition }),
          request_reason: requestReason,
        },
        show_toast: true,
      })
    );

    if (resp.error) {
    } else {
      
    }
  };

  /* UseEffects */
  useEffect(() => {
    // Make the states to default when the project is changed
    // access type buttons
    setSelectedPaBtn(
      selectedProjectDetails?.is_zonal ? moduleTypes?.ZONE : moduleTypes?.EE
    );
    // Zones
    setZoneOptionsList([]);
    setSelectedZone(null);
    setSelectedZoneDetails(null);
    // EE Components
    setEEOptionsList([]);
    setSelectedEE(null);
    setSelectedEEDetails(null);
    // Software Modules
    setSWMOptionsList([]);
    setSelectedSWM(null);
    setSelectedSWMDetails(null);
  }, [selectedProjectDetails]);

  useEffect(() => {
    // Make the reason for access to default, when the data is changed
    setReasonForAccess("");
  }, [
    selectedProjectDetails,
    selectedPaBtn,
    selectedAtBtn,
    selectedZone,
    selectedEE,
    selectedSWM,
  ]);

  useEffect(() => {
    // When the selected Zone is changed, make the EE Components state to default
    setEEOptionsList([]);
    setSelectedEE(null);
    setSelectedEEDetails(null);
  }, [selectedZone]);

  useEffect(() => {
    // When the selected Zone/EE Component is changed, make the Software Modules state to default
    setSWMOptionsList([]);
    setSelectedSWM(null);
    setSelectedSWMDetails(null);
  }, [selectedZone, selectedEE]);

  useEffect(() => {
    // let filteredCoOwnersOptionsList = selectedProjectDetails?.co_owner_details
    //   ?.map((el) => {
    //     if (el?.user_id) {
    //       return {
    //         value: el.user_id,
    //         label: `${el.name} - ${el.email}`,
    //       };
    //     }
    //     return null;
    //   })
    //   ?.filter(Boolean);
    // setCoOwnersOptionsList(filteredCoOwnersOptionsList);
    // // Select first co-owner by default
    // setSelectedCoOwner(selectedProjectDetails?.co_owner_details[0]?.user_id);

    // If Centralized project, Get the EE Components Details, When the project is selected
    if (
      selectedProjectDetails?.project_id &&
      !selectedProjectDetails?.is_zonal
    ) {
      getZoneEEDetails({
        projectId: selectedProjectDetails?.project_id,
      });
    }

    // Zones options list
    let filteredZoneOptionsList = selectedProjectDetails?.project_zones
      ?.map((el) => {
        return {
          value: el?.sequence,
          label: el?.zone_name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setZoneOptionsList(filteredZoneOptionsList);

    // Select the first zone, when the selected project is changed
    if (filteredZoneOptionsList?.length) {
      setSelectedZone(filteredZoneOptionsList[0]);
    }
  }, [selectedProjectDetails]);

  useEffect(() => {
    /* Update selected zone details based on the selected zone */
    let filteredSelectedZoneDetails =
      selectedProjectDetails?.project_zones?.find(
        (el) => el?.sequence == selectedZone?.value
      );
    setSelectedZoneDetails(filteredSelectedZoneDetails);

    // If Zonal project, Get the EE Components Details, When the zone is selected
    if (selectedProjectDetails?.project_id && selectedZone?.value) {
      getZoneEEDetails({
        projectId: selectedProjectDetails?.project_id,
        zoneSequence: selectedZone?.value,
      });
    }
  }, [selectedZone]);

  useEffect(() => {
    // EE Components Options List
    let filteredEEOptionsList = zoneEEDetails
      ?.map((el) => {
        return {
          value: el?.ee_id,
          label: el?.description,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setEEOptionsList(filteredEEOptionsList);

    // Select the first ee component, when the selected zone is changed
    if (filteredEEOptionsList?.length) {
      setSelectedEE(filteredEEOptionsList[0]);
    }
  }, [zoneEEDetails]);

  useEffect(() => {
    // When EE Component is selected, Get the Software Module Details
    if (selectedProjectDetails?.project_id && selectedEE?.value) {
      getEESwmDetails({
        projectId: selectedProjectDetails?.project_id,
        eeId: selectedEE?.value,
      });
    }

    // Get Selected EE Component details
    let filteredSelectedEEDetails = zoneEEDetails?.find(
      (el) => el?.ee_id == selectedEE?.value
    );
    setSelectedEEDetails(filteredSelectedEEDetails);
  }, [selectedEE]);

  useEffect(() => {
    // Software Module Options List
    let filteredSWMOptionsList = eeSwmDetails
      ?.map((el) => {
        return {
          value: el?.swm_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setSWMOptionsList(filteredSWMOptionsList);

    // Select the first software module, when the selected ee component is changed
    if (filteredSWMOptionsList?.length) {
      setSelectedSWM(filteredSWMOptionsList[0]);
    }
  }, [eeSwmDetails]);

  useEffect(() => {
    // Get Selected Software Module Details
    let filteredEESwmDetails = eeSwmDetails?.find(
      (el) => el?.swm_id == selectedSWM?.value
    );
    setSelectedSWMDetails(filteredEESwmDetails);
  }, [selectedSWM]);

  return (
    // New Request
    <Paper elevation={4} sx={styles.nrContainer}>
      {/* Module Access Types */}
      <ModuleAccessTypes
        selectedProjectDetails={selectedProjectDetails}
        selectedPaBtn={selectedPaBtn}
        setSelectedPaBtn={setSelectedPaBtn}
        moduleTypes={moduleTypes}
        hideProject={true}
      />

      {/* Request access */}
      <Paper elevation={4} sx={styles.nrMainContainer}>
        {/* Project Details */}
        <ProjectDetails
          selectedProjectDetails={selectedProjectDetails}
          projectStatus={projectStatus}
        />

        {/* Select Zone/EE Component/Software Module */}
        <SelectModules
          selectedProjectDetails={selectedProjectDetails}
          selectedPaBtn={selectedPaBtn}
          moduleTypes={moduleTypes}
          zoneOptionsList={zoneOptionsList}
          selectedZone={selectedZone}
          setSelectedZone={setSelectedZone}
          eeOptionsList={eeOptionsList}
          selectedEE={selectedEE}
          setSelectedEE={setSelectedEE}
          swmOptionsList={swmOptionsList}
          selectedSWM={selectedSWM}
          setSelectedSWM={setSelectedSWM}
        />

        {/* EE Component Details */}
        {selectedPaBtn === moduleTypes?.EE && selectedEE && (
          <EEDetails selectedEEDetails={selectedEEDetails} />
        )}

        {/* Software Module Details */}
        {selectedPaBtn === moduleTypes?.SWM && selectedSWM && (
          <SWMDetails selectedSWMDetails={selectedSWMDetails} />
        )}

        {/* Select Access type with reason and send request */}
        <Box sx={styles.nrATContainer}>
          {/* Access Types */}
          <Box sx={styles.nrATPContainer}>
            <Typography
              variant="subtitle1"
              sx={{ ...styles.nrDetailsTxt, marginRight: 1 }}
            >
              <span style={styles.txtBold}>Access Type: </span>
            </Typography>
            <ButtonGroup size="small">
              {[
                { value: accessTypes?.COOWNER, label: "Co-Owner" },
                { value: accessTypes?.EDITOR, label: "Editor" },
                { value: accessTypes?.VIEWER, label: "Viewer" },
              ].map((el) => (
                <Button
                  sx={{
                    color: "black",
                    borderRadius: 0,
                    borderColor: "#333",
                    backgroundColor:
                      // selectedAtBtn === el?.value ? "#26890D" : "transparent",
                      selectedAtBtn === el?.value ? customAppColors?.green : "transparent",
                    "&:hover": {
                      borderColor: "#333",
                      backgroundColor:
                        // selectedAtBtn == el?.value ? "#37b518" : "#f0f0f0",
                        customAppColors?.lightGreen,
                    },
                  }}
                  onClick={() => setSelectedAtBtn(el?.value)}
                >
                  {el?.label}
                </Button>
              ))}
            </ButtonGroup>
          </Box>

          <Box sx={styles.nrATTbContainer}>
            <Box sx={styles.nrATTContainer}>
              <CustomTextFieldN
                size={"small"}
                placeholder={"Reason for access"}
                type={"text"}
                isMultiline={true}
                minRows={3}
                maxRows={5}
                value={reasonForAccess}
                setValue={setReasonForAccess}
                variant={"outlined"}
                txtFldStyle={styles.customTextFieldN}
              />
            </Box>
            <Box sx={styles.nrATbContainer}>
              <CustomButton
                size={"small"}
                btnName={"Send Request"}
                disabled={
                  // There should be a reason for access
                  !reasonForAccess ||
                  reasonForAccess.length < 5 ||
                  // Project Access Validation
                  (selectedPaBtn === moduleTypes?.PROJECT &&
                    !selectedProjectDetails?.project_id) ||
                  // Zone Access Validation
                  (selectedPaBtn === moduleTypes?.ZONE &&
                    !selectedZone?.value) ||
                  // EE Component Access Validation
                  (selectedPaBtn === moduleTypes?.EE &&
                    (!selectedProjectDetails?.project_id ||
                      !selectedEEDetails?.ee_id)) ||
                  // Software Module Access Validation
                  (selectedPaBtn === moduleTypes?.SWM &&
                    (!selectedProjectDetails?.project_id ||
                      !selectedEEDetails?.ee_id ||
                      !selectedSWMDetails?.swm_id))
                }
                handleOnClick={() =>
                  requestAccess({
                    accessType:
                      selectedAtBtn === accessTypes?.COOWNER
                        ? accessTypes?.COOWNER
                        : selectedAtBtn === accessTypes?.EDITOR
                        ? accessTypes?.EDITOR
                        : accessTypes?.VIEWER,
                    moduleType:
                      selectedPaBtn === moduleTypes?.PROJECT
                        ? moduleTypes?.PROJECT
                        : selectedPaBtn === moduleTypes?.ZONE
                        ? moduleTypes?.ZONE
                        : selectedPaBtn === moduleTypes?.EE
                        ? moduleTypes?.EE
                        : moduleTypes?.SWM,
                    projectId: selectedProjectDetails?.project_id,
                    // zonePosition: (selectedPaBtn === moduleTypes?.ZONE || selectedPaBtn === moduleTypes?.EE || selectedPaBtn === moduleTypes?.SWM) && selectedZone?.value,
                    zonePosition:
                      (selectedPaBtn === moduleTypes?.ZONE ||
                        selectedPaBtn === moduleTypes?.EE ||
                        selectedPaBtn === moduleTypes?.SWM) &&
                      selectedZoneDetails?.position,
                    eeId:
                      (selectedPaBtn === moduleTypes?.EE ||
                        selectedPaBtn === moduleTypes?.SWM) &&
                      selectedEE?.value,
                    swmId:
                      selectedPaBtn === moduleTypes?.SWM && selectedSWM?.value,
                    requestReason: reasonForAccess,
                  })
                }
                // btnStyle={styles.customButton}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Paper>
  );
}

export default NewRequestTab;

// Styles
const styles = {
  nrContainer: {
    width: "100%",
    display: "flex",
    p: 2,
    mt: 3,
    border: "1px solid grey",
  },
  nrMainContainer: {
    width: "100%",
    border: "1px solid grey",
    ml: 3,
    px: 2,
    pt: 0.6,
    pb: 2,
  },
  nrATContainer: {
    my: 4,
  },
  nrATPContainer: {
    display: "flex",
  },
  nrDetailsTxt: {
    textAlign: "start",
  },
  txtBold: {
    fontWeight: "bold",
  },
  nrATTbContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    my: 4,
  },
  nrATTContainer: {
    flexGrow: 1,
  },
  customTextFieldN: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "black", // Hover border color
      },
      "&.Mui-focused fieldset": {
        borderColor: "black", // Focused border color
      },
    },
  },
  nrATbContainer: {
    ml: 2.5,
  },
};
