/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description This is the Virtual Labcar Page for the Application
 */

//Importing the required components/functions from the libraries
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { EEDetails, SearchSelectTextField } from "../../components";
import VlcMindMap from "./MindMap";
import VlcCarCard from "./CarCard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import LinkModulesMain from "./link-modules/LinkModulesMain";
import {
  HandleApiActions,
  labCar,
  profileActions,
  projectConfigAPI,
  userManagementApis,
} from "../../redux/actions";
import customConsole from "../../config/customConsole";
import VlcEEComponents from "./EEComponents";
import VehicleComponents from "./VehicleCompnents";
import VlcZonalMindMap from "./ZonalMindMap";
import appTheme from "../../assets/AppTheme/appTheme";
import InfoButton from "../../components/infoButton/InfoButton";
import { useLocation } from "react-router-dom";
import setSelectedProject from "../../redux/actions/selectProject";
import { CgKey } from "react-icons/cg";
import Divider from "@mui/material/Divider";
/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description : Main lab car function
 * @param : none
 * @return : The rendered VLC page.
 */

function LabCar() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const updatedSelectedProjectId = queryParams.get("project_id");

  const dispatch = useDispatch();

  /* useSelectors */
  const { projectDetails } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  const variantDetails = useSelector((state) => state.variantReducer);
  console.log("variantDetails", variantDetails);

  /* Project-related state variables */
  const [projectOptionsList, setProjectOptionsList] = useState([]);
  const [variantOptionList, setVariantOptionList] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  const [selectedVariantDetails, setSelectedVariantDetails] = useState(null);
  const [selectedSingleVariantDetails, setSelectedSingleVariantDetails] =
    useState([]);

  const [selectedVariant, setSelectedVariant] = useState(null);

  /* State for handling no projects found message */
  const [showNoProjectsFound, setShowNoProjectsFound] = useState(false);

  // Get Selected Project Details
  const getSelectedProjectDetails = async ({ projectId }) => {
    let apiDetails = labCar.get_project_details_pa;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
        },
      })
    );

    if (resp.error) {
      setSelectedProjectDetails([]);
    } else {
      setSelectedProjectDetails(resp.data);
    }
  };

  /* useEffects */
  useEffect(() => {
    // Get the project details
    dispatch(HandleApiActions({ ...labCar.get_project_details }));
    // Get the roles list
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          page_number: 1,
          page_size: 100,
          sort_column: "created_at",
          sort_order: "ASC",
        },
        show_toast: false,
      })
    );
    // Get the profile details
    dispatch(HandleApiActions({ ...profileActions.get_profile_details }));
  }, []);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.get_variant_details,
        params: {
          project_id: selectedProject?.value,
          is_complete_details_required: true,
        },
      })
    );
  }, [selectedProject]);

  useEffect(() => {
    /* One second delay for the show no project */
    if (projectDetails.length === 0) {
      const timer = setTimeout(() => {
        setShowNoProjectsFound(true);
      }, 1000);
      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    } else {
      setShowNoProjectsFound(false);
    }
  }, []);

  useEffect(() => {
    /* Update project options list and select the first project by default */
    if (projectDetails) {
      let filteredProjectOptionsList = projectDetails
        ?.map((el) => {
          return {
            value: el?.project_id,
            label: el?.name,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));

      setProjectOptionsList(filteredProjectOptionsList);

      // Try to find the project that matches the projectId
      const queryParams = new URLSearchParams(location.search);

      const projectId = queryParams.get("project_id");

      const selectedProject = filteredProjectOptionsList.find(
        (project) => project.value === projectId
      );

      if (selectedProject) {
        // If a matching project is found, set it as the selected project
        setSelectedProject(selectedProject);
      } else if (filteredProjectOptionsList.length) {
        // If no matching project is found, select the first project by default
        setSelectedProject({
          value: projectDetails[0]?.project_id,
          label: projectDetails[0]?.name,
        });
      }

      /* Select first project by default */
      // if (projectDetails[0]?.project_id && projectDetails[0]?.name) {
      //   setSelectedProject({
      //     value: projectDetails[0]?.project_id,
      //     label: projectDetails[0]?.name,
      //   });
      // }
    }
  }, [projectDetails]);

  //useEffect fot Variant
  useEffect(() => {
    if (variantDetails?.variantInfo?.length > 0) {
      const allVariantsDetails = variantDetails?.variantInfo?.filter(
        (data) => data?.project_id === selectedProject?.value
      );
      console.log("allVariantsDetails", allVariantsDetails);

      setSelectedVariantDetails(allVariantsDetails);
    }
  }, [variantDetails, selectedProject]);

  useEffect(() => {
    if (selectedVariantDetails?.length > 0 && selectedVariant) {
      const filteredSingleVariantsDetails = selectedVariantDetails?.find(
        (data) => data?.variant_id === selectedVariant?.value
      );
      setSelectedSingleVariantDetails(filteredSingleVariantsDetails);
      console.log(
        "filteredSingleVariantsDetails",
        filteredSingleVariantsDetails
      );
    }
  }, [selectedVariantDetails, selectedVariant, selectedProject]);

  useEffect(() => {
    if (
      Array.isArray(selectedVariantDetails) &&
      selectedVariantDetails.length > 0
    ) {
      const filteredVariantsDetails = selectedVariantDetails
        .filter((el) => el?.variant_id && el?.name)
        .map((el) => ({
          value: el.variant_id,
          label: el.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      if (filteredVariantsDetails.length > 0) {
        setSelectedVariant(filteredVariantsDetails[0]);
      }

      console.log("filteredVariantsDetails", filteredVariantsDetails);
      setVariantOptionList(filteredVariantsDetails);
    } else {
      console.warn("selectedVariantDetails is invalid or empty");
      setSelectedVariant(null);
      setVariantOptionList([]);
    }
  }, [selectedVariantDetails, selectedProject]);

  // Update select project when redirected from other page
  // useEffect(() => {
  //   let filteredProjectDetails = projectDetails?.find((el) => {
  //     return el.project_id == updatedSelectedProjectId;
  //   });

  //   if (filteredProjectDetails?.project_id && filteredProjectDetails?.name) {
  //     getSelectedProjectDetails({
  //       value: filteredProjectDetails?.project_id,
  //       label: filteredProjectDetails?.name,
  //     });
  //   }
  // }, [updatedSelectedProjectId]);

  useEffect(() => {
    // Get selected project details
    if (selectedProject) {
      getSelectedProjectDetails({ projectId: selectedProject?.value });
    }
  }, [selectedProject]);

  return (
    <Box>
      {projectDetails.length > 0 ? (
        // Project page
        <LabCarMain
          selectedSingleVariantDetails={selectedSingleVariantDetails}
          projectOptionsList={projectOptionsList}
          variantOptionList={variantOptionList}
          setProjectOptionsList={setProjectOptionsList}
          setSelectedVariant={setSelectedVariant}
          selectedVariant={selectedVariant}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          selectedVariantDetails={
            selectedVariantDetails?.length > 0 ? selectedVariantDetails : []
          }
          selectedProjectDetails={
            selectedProjectDetails?.length ? selectedProjectDetails[0] : []
          }
          // projectDetails={projectDetails}
          // projectId={projectId}
        />
      ) : (
        // No project found
        showNoProjectsFound && (
          <Box sx={styles.noPFContainer}>
            <Typography variant="h6" sx={styles.noPFTxt}>
              No Projects Found
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
}

export default LabCar;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description : Displays the vlc details.
 * @param : none
 * @return : The rendered VLC page.
 */
function LabCarMain({
  selectedSingleVariantDetails,
  projectOptionsList,
  setProjectOptionsList,
  selectedVariant,
  setSelectedVariant,
  selectedProject,
  setSelectedProject,
  selectedProjectDetails,
  selectedVariantDetails,
  variantOptionList,
  projectDetails,
  projectId,
}) {
  const dispatch = useDispatch();

  /* Extracting EE components list from the Redux store */
  const { eeComponentsList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* State for handling the software modules screen */
  const [isLinkSoftwareModulesScreen, setIsLinkSoftwareModulesScreen] =
    useState(false);

  /* Car Card */
  const [selectedView, setSelectedView] = useState("CAR");

  // Vehicle and EE Component state
  const [selectedVehicleView, setSelectedVehicleView] = useState("VEHICLE");

  /* Zones */
  const [availableZones, setAvailableZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedZoneDetails, setSelectedZoneDetails] = useState(null);

  /* Zone - EE */
  const [zoneEEList, setZoneEEList] = useState([]);
  /* Centralized - EE */
  const [centralizedEEList, setCentralizedEEList] = useState([]);

  /* Zone - EE Count */
  const [zoneEECountList, setZoneEECountList] = useState([]);

  /* Project - EE Count */
  const [projectEECount, setProjectEECount] = useState(0);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const queryLinkSoftwareModulesScreen = queryParams.get(
    "linkSoftwareModulesScreen"
  );

  useEffect(() => {
    if (queryLinkSoftwareModulesScreen) {
      setIsLinkSoftwareModulesScreen(true);
    }
  }, [queryLinkSoftwareModulesScreen]);

  /* useEffects */
  useEffect(() => {
    /* Make to default state when the project is changed */
    // setIsLinkSoftwareModulesScreen(false);
    // setSelectedView("CAR");
    setAvailableZones([]);
    setSelectedZone(null);
    setSelectedZoneDetails(null);
    setZoneEEList([]);
    setCentralizedEEList([]);
    setZoneEECountList([]);
    setProjectEECount(0);
  }, [selectedProjectDetails]);

  const getZoneEECountList = async (projectId, variantId) => {
    const resp = await dispatch(
      HandleApiActions({
        ...labCar.get_ee_components_count,
        params: {
          project_id: projectId,
          variant_id: variantId,
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setZoneEECountList([]);
    } else {
      if (resp?.data?.length > 0) {
        // Set the project total ee count
        setProjectEECount(resp?.data?.length || 0);

        const components = resp?.data;
        const counts = components?.reduce((acc, component) => {
          const position = component?.zone_details?.position;
          if (!acc[position]) {
            acc[position] = 0;
          }
          acc[position]++;
          return acc;
        }, {});

        const filteredZoneEECountList = Object?.entries(counts)?.map(
          ([zone_position, ee_components_count]) => ({
            zone_position,
            ee_components_count,
          })
        );
        if (filteredZoneEECountList?.length > 0) {
          setZoneEECountList(filteredZoneEECountList);
        } else {
          setZoneEECountList([]);
        }
      }
    }
  };
  console.log("selectedProjectDetails", selectedProjectDetails);

  useEffect(() => {
    /* Zones */
    if (selectedProjectDetails && selectedProjectDetails?.is_zonal) {
      let filteredAvailableZones = selectedProjectDetails?.project_zones?.map(
        (el) => {
          return {
            zone_position: el.position,
            position_name: el.zone_name,
          };
        }
      );
      setAvailableZones(filteredAvailableZones);
    }

    if (
      selectedProjectDetails &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id &&
      // selectedProjectDetails?.is_zonal &&
      selectedProjectDetails?.project_id
    ) {
      getZoneEECountList(
        selectedProjectDetails?.project_id,
        selectedSingleVariantDetails?.variant_id
      );
    }
  }, [selectedProjectDetails]);

  useEffect(() => {
    /* Update selected zone details based on the selected zone */
    if (
      selectedZone &&
      selectedZone?.zone_position_name &&
      selectedProjectDetails &&
      selectedProjectDetails?.project_zones
    ) {
      let filteredSelectedZoneDetails =
        selectedProjectDetails?.project_zones?.find(
          (el) => el?.zone_name == selectedZone?.zone_position_name
        );
      setSelectedZoneDetails(filteredSelectedZoneDetails);
    }
  }, [selectedZone]);

  const getEEComponentsList = () => {
    /* Fetch EE components based on the selected project and zone details */
    if (
      (selectedZoneDetails &&
        selectedSingleVariantDetails &&
        selectedSingleVariantDetails?.variant_id &&
        selectedZoneDetails.sequence &&
        selectedProjectDetails &&
        selectedProjectDetails?.project_id) ||
      (selectedProjectDetails &&
        selectedProjectDetails?.project_id &&
        !selectedProjectDetails.is_zonal)
    ) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components,
          params: {
            project_id: selectedProjectDetails?.project_id,
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ...(selectedProjectDetails.is_zonal && {
              // zone_sequence: selectedZoneDetails.sequence,
              zone_position: selectedZoneDetails?.position,
            }),
          },
          show_toast: false,
        })
      );
    }
  };

  useEffect(() => {
    getEEComponentsList();
  }, [
    selectedZoneDetails,
    selectedProjectDetails,
    selectedSingleVariantDetails,
  ]);

  useEffect(() => {
    /* Update EE components list based on the fetched EE components */
    if (eeComponentsList) {
      if (selectedProjectDetails && selectedProjectDetails.is_zonal) {
        setZoneEEList(eeComponentsList);
      } else {
        setCentralizedEEList(eeComponentsList);
      }
    }
  }, [eeComponentsList]);

  useEffect(() => {
    customConsole("selectedZoneDetails: " + selectedZoneDetails);
    customConsole("selectedZone: " + selectedZone);
    customConsole("selectedProjectDetails: " + selectedProjectDetails);
    customConsole("zoneEEList " + zoneEEList);
    customConsole({ zoneEECountList });
  }, [selectedZoneDetails, zoneEEList, zoneEECountList]);

  /* -------------- Handle Zone EE functions -------------- */
  /* Add a new EE component */
  const handleZoneEEAdd = ({
    assembly_number,
    supplier_name,
    version,
    summary,
    description,
    vehicle_systems,
  }) => {
    if (
      (selectedZoneDetails &&
        selectedZoneDetails.sequence &&
        selectedSingleVariantDetails?.variant_id &&
        selectedSingleVariantDetails &&
        selectedProjectDetails &&
        selectedProjectDetails?.project_id) ||
      (selectedProjectDetails &&
        selectedProjectDetails?.project_id &&
        selectedSingleVariantDetails?.variant_id &&
        selectedSingleVariantDetails &&
        !selectedProjectDetails.is_zonal)
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ...(selectedProjectDetails.is_zonal && {
          zone_position: selectedZoneDetails?.zone_name,
          // zone_sequence: selectedZoneDetails.sequence,
        }),
        assembly_number,
        supplier_name,
        version,
        ...(summary && { summary }),
        description,
        vehicle_systems,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.add_ee_component,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ...(selectedProjectDetails.is_zonal && {
              zone_position: selectedZoneDetails?.position,
              // zone_sequence: selectedZoneDetails.sequence,
            }),
            assembly_number,
            supplier_name,
            version,
            ...(summary && { summary }),
            description,
            vehicle_systems,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getEEComponentsList();
        getZoneEECountList(
          selectedProjectDetails?.project_id,
          selectedSingleVariantDetails?.variant_id
        );
      });
    }
  };

  /* Update an existing EE component */
  const handleZoneEEUpdate = ({ ee_id, version, will_it_impact, reason }) => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id,
        version,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.update_ee_component,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ee_id,
            version,
            will_it_impact,
            reason,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getEEComponentsList();
      });
    }
  };

  /* Remove an EE component */
  const handleZoneEEReplace = ({
    ee_id,
    assembly_number,
    supplier_name,
    version,
    summary,
    description,
    vehicle_systems,
    will_it_impact,
    reason,
  }) => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id,
        assembly_number,
        supplier_name,
        version,
        ...(summary && { summary }),
        description,
        vehicle_systems,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.replace_ee_component,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ee_id,
            assembly_number,
            supplier_name,
            version,
            ...(summary && { summary }),
            description,
            vehicle_systems,
            reason,
            will_it_impact,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getEEComponentsList();
      });
    }
  };
  const infoKey =
    selectedProjectDetails?.architecture_name === "ZONAL"
      ? "zonal_architecture"
      : "centralized_architecture";

  const handleViewChange = (view) => {
    setSelectedVehicleView(view);
  };

  console.log("selectedZoneDetails", selectedZoneDetails);
  return (
    <Box sx={styles.mainContainer}>
      {/* Search Select Text Field, Link Software Modules Button */}
      <Box sx={styles.topContainer}>
        {selectedProject && !isLinkSoftwareModulesScreen && (
          <Box sx={styles.linkBtnContainer}>
            <Button
              sx={styles.linkBtn}
              color="primary"
              variant="contained"
              onClick={() => setIsLinkSoftwareModulesScreen(true)}
            >
              Link Modules
              <InfoButton infoKey={"dependencies"} color={"white"} />
            </Button>
          </Box>
        )}
        {!isLinkSoftwareModulesScreen && (
          <>
            <Box sx={styles.searchContainer}>
              <SearchSelectTextField
                size={"small"}
                label={"Search Project"}
                placeholder={"Search Project"}
                noOptionsText={"No project found"}
                optionsList={projectOptionsList}
                selectedValue={selectedProject}
                setSelectedValue={setSelectedProject}
                txtFldStyle={styles.searchSelectTextField}
              />
            </Box>
            <Box sx={styles.searchContainer}>
              <SearchSelectTextField
                size={"small"}
                label={"Search Variant"}
                placeholder={"Search Variant"}
                noOptionsText={"No variant found"}
                optionsList={variantOptionList || []}
                selectedValue={selectedVariant}
                setSelectedValue={setSelectedVariant}
                txtFldStyle={styles.searchSelectTextField}
              />
            </Box>
          </>
        )}
        {selectedProject && !isLinkSoftwareModulesScreen && (
          // Buttons to select the car or mind map
          <Box sx={styles.switchBtns}>
            {/* {selectedView === "MAP" ? (
              <Button
                sx={{ ...appTheme.commonBtnStyle, mr: 2.5 }}
                onClick={() => {
                  window.open(
                    "https://app.mural.co/t/mbvans9255/m/mbvans9255/1724825550085/c41043c3786e1450c9aa41dc9e2c8b486138678f?sender=9afb4ad3-e2dd-45ef-bfdd-63bae43db612",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                UPCOMING VIEW
              </Button>
            ) : null} */}
            <Button
              sx={
                selectedView === "CAR"
                  ? { ...appTheme.commonBtnStyle }
                  : {
                      ...appTheme.commonBtnStyle,
                      backgroundColor: "#b7d0b7",
                      color: "black",
                      border: "1px solid black",
                    }
              }
              onClick={() => setSelectedView("CAR")}
            >
              CAR VIEW
              <InfoButton infoKey={"car_view"} color={"white"} />
            </Button>
            <Button
              sx={
                selectedView === "MAP"
                  ? { ...appTheme.commonBtnStyle, ml: 1 }
                  : {
                      ...appTheme.commonBtnStyle,
                      ml: 1,
                      backgroundColor: "#b7d0b7",
                      color: "black",
                      border: "1px solid black",
                    }
              }
              onClick={() => setSelectedView("MAP")}
            >
              MIND MAP
              <InfoButton infoKey={"mind_map"} color={"grey"} />
            </Button>
          </Box>
        )}
      </Box>

      {/* Vlc */}
      {selectedProject && (
        <Box>
          {selectedProjectDetails &&
          selectedProjectDetails?.project_id &&
          // selectedProjectDetails?.project_zones &&
          // selectedProjectDetails?.ee_ids &&
          // selectedProjectDetails?.name &&
          // selectedProjectDetails?.architecture_name &&
          // selectedProjectDetails?.trade_mark &&
          // selectedProjectDetails?.vehicle_platform &&
          isLinkSoftwareModulesScreen ? (
            // Link Software Modules Screen
            <LinkModulesMain
              selectedSingleVariantDetails={selectedSingleVariantDetails}
              selectedProjectId={selectedProjectDetails?.project_id}
              selectedProjectIsZonal={selectedProjectDetails?.is_zonal}
              selectedProjectZonesList={selectedProjectDetails?.project_zones}
              selectedProjectName={selectedProjectDetails?.name}
              selectedProjectArchitecture={
                selectedProjectDetails?.architecture_name
              }
              selectedProjectTradeMark={selectedProjectDetails?.trade_mark}
              selectedProjectVehiclePlatform={
                selectedProjectDetails?.vehicle_platform
              }
              selectedProjectTotalZones={
                selectedProjectDetails?.project_zones?.length
              }
              selectedProjectTotalEEComponents={
                projectEECount > selectedProjectDetails?.ee_ids?.length
                  ? projectEECount
                  : selectedProjectDetails?.ee_ids?.length
              }
              isLinkSoftwareModulesScreen={isLinkSoftwareModulesScreen}
              setSelectedView={setSelectedView}
              setIsLinkSoftwareModulesScreen={setIsLinkSoftwareModulesScreen}
            />
          ) : (
            <Box sx={styles.container}>
              <Grid container>
                <Grid xs={6}>
                  <Paper elevation={6} sx={styles.prjDetailsContainer}>
                    <Typography
                      sx={{ fontSize: "18px", fontWeight: "bold", pt: 0.4 }}
                    >
                      Project Details
                    </Typography>
                    <Divider sx={{ mt: 0.4, mb: 1 }} />
                    <Grid container>
                      {/* Name Section */}
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={styles.prjDetailsTxt}
                          >
                            <span style={styles.txtBold}>Name: </span>
                          </Typography>
                          <Typography
                            sx={{ textAlign: "start" }}
                            variant="subtitle1"
                          >
                            {selectedProjectDetails?.name}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Architecture Section */}
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={styles.prjDetailsTxt}
                          >
                            <span style={styles.txtBold}>Architecture: </span>
                          </Typography>
                          <Typography
                            sx={{ textAlign: "start" }}
                            variant="subtitle1"
                          >
                            {selectedProjectDetails?.architecture_name}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Trade Mark Section */}
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={styles.prjDetailsTxt}
                          >
                            <span style={styles.txtBold}>Trade Mark: </span>
                          </Typography>
                          <Typography
                            sx={{ textAlign: "start" }}
                            variant="subtitle1"
                          >
                            {selectedProjectDetails?.trade_mark}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Vehicle Platform Section */}
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={styles.prjDetailsTxt}
                          >
                            <span style={styles.txtBold}>
                              Vehicle Platform:{" "}
                            </span>
                          </Typography>
                          <Typography
                            sx={{ textAlign: "start" }}
                            variant="subtitle1"
                          >
                            {selectedProjectDetails?.vehicle_platform}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Zones Section */}
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={styles.prjDetailsTxt}
                          >
                            <span style={styles.txtBold}>Zones: </span>
                          </Typography>
                          <Typography
                            sx={{ textAlign: "start" }}
                            variant="subtitle1"
                          >
                            {selectedProjectDetails?.is_zonal
                              ? selectedProjectDetails?.project_zones?.length
                              : "-"}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* EE Components Section */}
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={styles.prjDetailsTxt}
                          >
                            <span style={styles.txtBold}>EE Components: </span>
                          </Typography>
                          <Typography
                            sx={{ textAlign: "start" }}
                            variant="subtitle1"
                          >
                            {projectEECount >
                            selectedProjectDetails?.ee_ids?.length
                              ? projectEECount
                              : selectedProjectDetails?.ee_ids?.length}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid xs={6}>
                  <Paper
                    elevation={6}
                    sx={styles.prjDetailsContainer}
                    key={selectedSingleVariantDetails?.id}
                  >
                    <Typography
                      sx={{ fontSize: "18px", fontWeight: "bold", pt: 0.4 }}
                    >
                      Variant Details
                    </Typography>
                    <Divider sx={{ mt: 0.4, mb: 1 }} />
                    <Grid container>
                      {/* Name Section */}
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={styles.prjDetailsTxt}
                          >
                            <span style={styles.txtBold}>Name: </span>
                          </Typography>
                          <Typography
                            sx={{ textAlign: "start" }}
                            variant="subtitle1"
                          >
                            {selectedSingleVariantDetails?.name}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Power Train Class Section */}
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={styles.prjDetailsTxt}
                          >
                            <span style={styles.txtBold}>
                              Power Train Class:{" "}
                            </span>
                          </Typography>
                          <Typography
                            sx={{ textAlign: "start" }}
                            variant="subtitle1"
                          >
                            {
                              selectedSingleVariantDetails
                                ?.powertrain_class_details?.[0]?.name
                            }
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Vehicle Status Section */}
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={styles.prjDetailsTxt}
                          >
                            <span style={styles.txtBold}>Vehicle Status: </span>
                          </Typography>
                          <Typography
                            sx={{ textAlign: "start" }}
                            variant="subtitle1"
                          >
                            {selectedSingleVariantDetails?.vehicle_status}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Delivery Market Section */}
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={styles.prjDetailsTxt}
                          >
                            <span style={styles.txtBold}>
                              Delivery Market:{" "}
                            </span>
                          </Typography>
                          <Typography
                            sx={{ textAlign: "start" }}
                            variant="subtitle1"
                          >
                            {selectedSingleVariantDetails?.delivery_market}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Vehicle Components Section */}
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={styles.prjDetailsTxt}
                          >
                            <span style={styles.txtBold}>
                              Vehicle Components:{" "}
                            </span>
                          </Typography>
                          <Typography
                            sx={{ textAlign: "start" }}
                            variant="subtitle1"
                          >
                            {
                              selectedSingleVariantDetails?.vehicle_components
                                ?.length
                            }
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              {selectedView === "CAR" ? (
                <Box
                  display="flex"
                  gap={2}
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{ pb: 2, mt: 2 }}
                >
                  <Button
                    onClick={() => handleViewChange("VEHICLE")}
                    variant={
                      selectedVehicleView === "VEHICLE"
                        ? "contained"
                        : "outlined"
                    }
                    sx={{
                      color:
                        selectedVehicleView === "VEHICLE" ? "white" : "black",
                      backgroundColor:
                        selectedVehicleView === "VEHICLE" ? "#26890D" : "white",
                      border:
                        selectedVehicleView === "VEHICLE"
                          ? "none"
                          : "1px solid #26890D",
                      maxHeight: "30px",
                      width: "fit-content",
                      borderRadius: "20px",
                      "&:hover": {
                        backgroundColor:
                          selectedVehicleView === "VEHICLE"
                            ? "#37b518"
                            : "#f0f0f0",
                      },
                    }}
                  >
                    Vehicle
                  </Button>

                  <Button
                    onClick={() => handleViewChange("ECU")}
                    variant={
                      selectedVehicleView === "ECU" ? "contained" : "outlined"
                    }
                    sx={{
                      color: selectedVehicleView === "ECU" ? "white" : "black",
                      backgroundColor:
                        selectedVehicleView === "ECU" ? "#26890D" : "white",
                      border:
                        selectedVehicleView === "ECU"
                          ? "none"
                          : "1px solid #26890D",
                      maxHeight: "30px",
                      width: "fit-content",
                      borderRadius: "20px",
                      "&:hover": {
                        backgroundColor:
                          selectedVehicleView === "ECU" ? "#37b518" : "#f0f0f0",
                      },
                    }}
                  >
                    ECU
                  </Button>
                </Box>
              ) : null}

              {selectedView === "CAR" ? (
                // Show the car view
                <Box sx={styles.carContainer}>
                  <Box sx={styles.carCardContainer}>
                    <VlcCarCard
                      availableZones={availableZones}
                      selectedComponent={selectedZone}
                      setSelectedComponent={setSelectedZone}
                      zoneEECountList={zoneEECountList}
                    />
                  </Box>

                  {/* EE component */}
                  <Box sx={styles.eeCompContainer}>
                    {selectedVehicleView === "VEHICLE" ? (
                      <VehicleComponents
                        selectedVariant={selectedVariant}
                        selectedVariantDetails={selectedVariantDetails}
                        selectedProjectDetails={selectedProjectDetails}
                        variantOptionList={variantOptionList}
                      />
                    ) : (
                      <VlcEEComponents
                        selectedSingleVariantDetails={
                          selectedSingleVariantDetails
                        }
                        heading={
                          selectedProjectDetails.is_zonal
                            ? selectedZoneDetails?.zone_name
                            : "Centralized"
                        }
                        eEComponentsList={
                          selectedProjectDetails.is_zonal
                            ? zoneEEList
                            : centralizedEEList
                        }
                        handleEEComponentAdd={handleZoneEEAdd}
                        handleEEComponentUpdate={handleZoneEEUpdate}
                        handleEEComponentReplace={handleZoneEEReplace}
                        selectedProjectDetails={selectedProjectDetails}
                        selectedZone={selectedZone}
                        setIsLinkSoftwareModulesScreen={
                          setIsLinkSoftwareModulesScreen
                        }
                        getEEComponentsList={getEEComponentsList}
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <>
                  {/* Show mind map */}
                  {selectedProjectDetails &&
                  selectedProjectDetails?.project_id &&
                  !selectedProjectDetails.is_zonal ? (
                    <VlcMindMap
                      selectedProjectId={selectedProjectDetails?.project_id}
                    />
                  ) : (
                    <VlcZonalMindMap
                      selectedProjectId={selectedProjectDetails?.project_id}
                    />
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

// Styles
const styles = {
  mainContainer: {
    overflow: "auto",
  },
  topContainer: {
    mt: 1,
    mx: 2,
    flexDirection: { xs: "column", md: "row" },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchContainer: {
    textAlign: "start",
    mt: { xs: 2, md: 0 },
  },
  switchBtns: {
    mt: { xs: 2, md: 0 },
  },
  linkBtnContainer: {
    mt: { xs: 2, md: 0 },
  },
  linkBtn: {
    // mt: { xs: 2, sm: 0 },
    // ml: { sm: 4 },
    // width: "215px",
    ...appTheme.commonBtnStyle,
  },
  container: {
    margin: "20px",
    marginBottom: "100px",
    display: "flex",
    flexDirection: "column",
  },
  detailsTxt: {
    flex: 1,
    textAlign: "center",
    // fontWeight: "bold",
  },
  carContainer: {
    width: "100%",
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    justifyContent: { xs: "center", lg: "space-evenly" },
    alignItems: { xs: "center", lg: "space-evenly" },
    overflowX: "auto",
    px: 2,
    pb: 4,
  },
  carCardContainer: {
    width: "520px",
  },
  eeCompContainer: {
    display: "flex",
    justifyContent: "center",
  },
  noPFContainer: {
    mt: 12,
  },
  noPFTxt: {
    mb: 1,
  },
  searchSelectTextField: {
    width: "215px",
    backgroundColor: "white",
  },
  prjDetailsContainer: {
    px: 2,
    // py: 1,
    mx: 1,
    height: "100%",
  },
  prjDetailsTxt: {
    textAlign: "start",
    alignContent: "center",
  },
  txtBold: {
    fontWeight: "bold",
  },
};
