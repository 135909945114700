/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description This is the Home Page for the Application
 */

//Importing the required components/functions from the libraries

import React, { useEffect, useState } from "react";
import customConsole from "../../config/customConsole";
import Chart from "react-google-charts";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  dashboardAction,
  HandleApiActions,
  labCar,
  userManagementApis,
} from "../../redux/actions";
import {
  CustomDataGridTableB,
  SearchSelectTextField,
  SelectTextFieldN,
} from "../../components";
import {
  Card,
  CardContent,
  Chip,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Box,
  Grid,
  Typography,
  DialogTitle,
  IconButton,
  DialogContent,
  styled,
  Button,
  Dialog,
  Divider,
  DialogActions,
  Slide,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import appTheme from "../../assets/AppTheme/appTheme";
import { Link } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CustomFilterIconN from "../../components/tables/CustomFilterIconN";
import setSelectedProject from "../../redux/actions/selectProject"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const hsOptions = {
  // title: "Homologation Status",
  pieHole: 0.47,
  is3D: false,
  legend: {
    position: "bottom",
    alignment: "center",
  },
  colors: [
    "#007CB0", // Total
    "#26890D", // Completed
  ],
  slices: {
    0: { visibleInLegend: true }, // Total
    1: { visibleInLegend: true }, // Completed
  },
  // titleTextStyle: {
  //   fontSize: 18,
  //   bold: true,
  //   color: "#333333",
  //   alignment: "center",
  // },
};

const tsOptions = {
  // title: "Tickets Status",
  legend: { position: "none" },
  vAxis: {
    viewWindow: {
      min: 0, // Ensures the minimum value on the Y-axis is 0
    },
  },
  // colors: [
  //   "#C55A11", // Draft
  //   "#00B0F0", // New
  //   "#C5E0B4", // Artifact Confirmation
  //   "#AFABAB", // Evidence Submission
  //   "#92D050", // Evidence Review
  //   "#00B050", // Testing at Technical Service
  //   "#FF0000", // Resolved
  // ],
  // titleTextStyle: {
  //   fontSize: 18,
  //   bold: true,
  //   color: "#333333",
  //   alignment: "center",
  // },
};

const ultOptions = {
  // title: "User Level Tickets",
  legend: { position: "none" },
  // titleTextStyle: {
  //   fontSize: 18,
  //   bold: true,
  //   color: "#333333",
  //   alignment: "center",
  // },
};

const ticketStatusNames = {
  101: "Draft",
  100: "New",
  150: "Confirm Legal Requirements",
  200: "Artifact Confirmation",
  300: "Evidence Submission",
  400: "Evidence Review",
  500: "Testing at Technical Service",
  600: "Resolved",
  999: "Closed Incomplete",
};
const resolvedTicketsStatus = [600];

const tsChartsStyles = {
  101: "#D0D0CE", // Draft
  100: "#0D8390", // New
  200: "#007CB0", // Artifact Confirmation
  300: "#86BC25", // Evidence Submission
  400: "#43B02A", // Evidence Review
  500: "#26890D", // Testing at Technical Service
  600: "#046A38", // Resolved
};

//Main Home Function component
function Home() {
  const dispatch = useDispatch();

  /* useSelectors */
  const usersDetails = useSelector((state) => state.userManagementReducer);

  const { projectDetails, selectedProject } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* Project-related state variables */
  const [projectOptionsList, setProjectOptionsList] = useState([]);
  // const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);

  // Contains the fetched ticket status data
  const [ticketsStatusData, setTicketsStatusData] = useState([]);
  // Contains the filtered fetched ticket status data
  const [ticketsStatusList, setTicketsStatusList] = useState([]);

  // Vehicle Functions Options List
  const [vehicleFunctionList, setVehicleFunctionList] = useState([]);

  // Users
  const [tsUsersList, setTsUsersList] = useState([]);

  // Homologation Status States
  const [hsVfOptionsList, setHsVfOptionsList] = useState([]);
  const [selectedHsVf, setSelectedHsVf] = useState(0);
  // Tickets Status States
  const [tsVfOptionsList, setTsVfOptionsList] = useState([]);
  const [selectedTsVf, setSelectedTsVf] = useState(0);
  // User Level Tickets States
  const [ultUsersOptionsList, setUltUsersOptionsList] = useState([]);
  const [selectedUltUser, setSelectedUltUser] = useState(0);

  // EE Dialog
  const [isEEDialogOpen, setIsEEDialogOpen] = useState(false);
  const [eeDialogData, setEEDialogData] = useState(null);

  const [homologationStatus, setHomologationStatus] = useState([
    ["Status", "Tickets"],
    ["Target", 0],
    ["Completion", 0],
  ]);
  const [totalHsTickets, setTotalHsTickets] = useState(0);
  const [ticketsStatus, setTicketsStatus] = useState([
    ["Status", "Count"],
    ["Draft", 0],
    ["New", 0],
    ["Artifact Confirmation", 0],
    ["Evidence Submission", 0],
    ["Evidence Review", 0],
    ["Testing at Technical Service", 0],
    ["Resolved", 0],
  ]);
  const [totalVfTickets, setTotalVfTickets] = useState(0);
  const [userLevelTickets, setUserLevelTickets] = useState([
    ["Status", "Count"],
    ["Draft", 0],
    ["New", 0],
    ["Artifact Confirmation", 0],
    ["Evidence Submission", 0],
    ["Evidence Review", 0],
    ["Testing at Technical Service", 0],
    ["Resolved", 0],
  ]);
  const [totalUserTickets, setTotalUserTickets] = useState(0);

  // Filters Icon
  const [anchorElHsFilter, setAnchorElHsFilter] = useState(null);
  const [anchorElTsFilter, setAnchorElTsFilter] = useState(null);
  const [anchorElUltFilter, setAnchorElUltFilter] = useState(null);

  const [openHsFilter, setOpenHsFilter] = useState(false);
  const [openTsFilter, setOpenTsFilter] = useState(false);
  const [openUltFilter, setOpenUltFilter] = useState(false);

  const handleHsFilterIconClick = (event) => {
    setAnchorElHsFilter(event.currentTarget);
    setOpenHsFilter(true);
  };
  const handleTsFilterIconClick = (event) => {
    setAnchorElTsFilter(event.currentTarget);
    setOpenTsFilter(true);
  };
  const handleUltFilterIconClick = (event) => {
    setAnchorElUltFilter(event.currentTarget);
    setOpenUltFilter(true);
  };

  const handleHsFilterIconClose = () => {
    setAnchorElHsFilter(null);
    setOpenHsFilter(false);
  };
  const handleTsFilterIconClose = () => {
    setAnchorElTsFilter(null);
    setOpenTsFilter(false);
  };
  const handleUltFilterIconClose = () => {
    setAnchorElUltFilter(null);
    setOpenUltFilter(false);
  };

  // Functions to update the charts data
  const updateHomologationStatus = ({ pending, resolved, total }) => {
    setTotalHsTickets(total);
    setHomologationStatus([
      ["Status", "Tickets"],
      ["Target", pending],
      ["Completion", resolved],
    ]);
  };

  // Function to get the tickets status
  const getTicketsStatus = async (projectId) => {
    const apiDetails = dashboardAction.get_tickets_status;
    customConsole({
      ...apiDetails,
      params: {
        ...(projectId && { project_id: projectId }),
      },
    });
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          ...(projectId && { project_id: projectId }),
        },
      })
    );
    if (resp.error) {
      setTicketsStatusData([]);
    } else {
      setTicketsStatusData(resp.data);
    }
  };

  // Function to update the charts data
  const updateTicketsStatus = (statusData) => {
    // Initialize counts for each status
    const statusCounts = Object.keys(ticketStatusNames)?.reduce(
      (acc, status) => {
        acc[status] = 0;
        return acc;
      },
      {}
    );

    // Count the occurrences of each status
    statusData?.forEach(({ ticket_status }) => {
      if (statusCounts.hasOwnProperty(ticket_status)) {
        statusCounts[ticket_status] += 1;
      }
    });

    // Calculate total count of all tickets
    const totalCount = Object.values(statusCounts).reduce(
      (acc, count) => acc + count,
      0
    );
    setTotalVfTickets(totalCount);

    // Construct the updated status array, including all statuses with their counts
    const updatedStatus = [
      ["Status", "Count", { role: "style" }, { role: "annotation" }],
      ["Draft", statusCounts[101], tsChartsStyles[101], statusCounts[101]],
      ["New", statusCounts[100], tsChartsStyles[100], statusCounts[100]],
      [
        "Artifact Confirmation",
        statusCounts[200],
        tsChartsStyles[200],
        statusCounts[200],
      ],
      [
        "Evidence Submission",
        statusCounts[300],
        tsChartsStyles[300],
        statusCounts[300],
      ],
      [
        "Evidence Review",
        statusCounts[400],
        tsChartsStyles[400],
        statusCounts[400],
      ],
      [
        "Testing at Technical Service",
        statusCounts[500],
        tsChartsStyles[500],
        statusCounts[500],
      ],
      ["Resolved", statusCounts[600], tsChartsStyles[600], statusCounts[600]],
    ];

    // Update the state with the new status data
    setTicketsStatus(updatedStatus);
  };

  // Function to update the charts data
  const updateUserTicketsStatus = (statusData) => {
    // Initialize counts for each status
    const statusCounts = Object.keys(ticketStatusNames).reduce(
      (acc, status) => {
        acc[status] = 0;
        return acc;
      },
      {}
    );

    // Count the occurrences of each status
    statusData.forEach(({ ticket_status }) => {
      if (statusCounts.hasOwnProperty(ticket_status)) {
        statusCounts[ticket_status] += 1;
      }
    });

    // Calculate total count of all tickets
    const totalCount = Object.values(statusCounts).reduce(
      (acc, count) => acc + count,
      0
    );
    setTotalUserTickets(totalCount);

    // Construct the updated status array, including all statuses with their counts
    const updatedStatus = [
      ["Status", "Count", { role: "style" }, { role: "annotation" }],
      ["Draft", statusCounts[101], tsChartsStyles[101], statusCounts[101]],
      ["New", statusCounts[100], tsChartsStyles[100], statusCounts[100]],
      [
        "Artifact Confirmation",
        statusCounts[200],
        tsChartsStyles[200],
        statusCounts[200],
      ],
      [
        "Evidence Submission",
        statusCounts[300],
        tsChartsStyles[300],
        statusCounts[300],
      ],
      [
        "Evidence Review",
        statusCounts[400],
        tsChartsStyles[400],
        statusCounts[400],
      ],
      [
        "Testing at Technical Service",
        statusCounts[500],
        tsChartsStyles[500],
        statusCounts[500],
      ],
      ["Resolved", statusCounts[600], tsChartsStyles[600], statusCounts[600]],
    ];

    // Update the state with the new status data
    setUserLevelTickets(updatedStatus);
  };

  /* UseEffects */
  useEffect(() => {
    // Get the project details
    dispatch(HandleApiActions({ ...labCar.get_project_details }));
  }, []);

  useEffect(() => {
    // Project options list
    let filteredProjectOptionsList = projectDetails
      ?.map((el) => {
        return {
          value: el?.project_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setProjectOptionsList(filteredProjectOptionsList);

    /* Select first project by default */
    if (!selectedProject && filteredProjectOptionsList.length) {
      dispatch(setSelectedProject(filteredProjectOptionsList[0]));
    }
  }, [projectDetails]);

  useEffect(() => {
    if (selectedProject) {
      let filteredSelectedProjectDetails = projectDetails?.find(
        (el) => el.project_id === selectedProject.value
      );
      setSelectedProjectDetails(filteredSelectedProjectDetails);
    }
  }, [projectDetails, selectedProject]);

  console.log("selectedProjectREDUX",selectedProject)

  const handleProjectChange = (newSelectedProject) => {
    dispatch(setSelectedProject(newSelectedProject));
  };

  console.log("selectedProjectHOME",selectedProject)

  useEffect(() => {
    // Make the stated to default, when the project is changed
    setTicketsStatusData([]);
    setTicketsStatusList([]);
    setVehicleFunctionList([]);
    setTsUsersList([]);
    setHsVfOptionsList([]);
    setSelectedHsVf(0);
    setTsVfOptionsList([]);
    setSelectedTsVf(0);
    setUltUsersOptionsList([]);
    setSelectedUltUser(0);
    setTotalHsTickets(0);
    setTotalVfTickets(0);
    setTotalUserTickets(0);
  }, [selectedProjectDetails]);

  useEffect(() => {
    /* Update selected project details based on the selected project */
    if (selectedProject) {
      let filteredSelectedProjectDetails = projectDetails?.find(
        (el) => el?.project_id == selectedProject?.value
      );

      setSelectedProjectDetails(filteredSelectedProjectDetails);
    }
  }, [selectedProject]);

  useEffect(() => {
    getTicketsStatus(
      isNaN(Number(selectedProjectDetails?.project_id))
        ? undefined
        : Number(selectedProjectDetails?.project_id)
    );
  }, [selectedProjectDetails]);

  useEffect(() => {
    // Filtered Tickets Status List
    let filteredTicketStatusList = ticketsStatusData?.map((ts) => {
      return {
        ticket_id: ts?.ticket_id,
        ticket_status: ts?.ticket_status,
        ticket_status_name: ticketStatusNames[ts?.ticket_status],
        vf_ids: ts?.vf_ids?.map((vf) => String(vf)),
        oem_user_ids: ts?.oem_user_ids?.map((oem) => String(oem)),
        tech_service_user_ids: ts?.tech_service_user_ids?.map((tsu) =>
          String(tsu)
        ),
      };
    });
    setTicketsStatusList(filteredTicketStatusList);

    // Filtered Vehicle Functions List
    const uniqueVehicleFunctions = ticketsStatusData?.reduce((acc, ticket) => {
      ticket?.vehicle_functions?.forEach((vf) => {
        if (!acc?.some((item) => item?.id == vf?.id)) {
          acc.push(vf);
        }
      });
      return acc;
    }, []);

    // Vehicle functions list
    setVehicleFunctionList(uniqueVehicleFunctions);

    // Filter users list
    const uniqueUsers = ticketsStatusData?.reduce((accumulator, current) => {
      const users = [
        ...(current?.tech_user_details || []),
        ...(current?.oem_user_details || []),
      ];
      users?.forEach((user) => {
        const userExists = accumulator?.some(
          (existingUser) => existingUser?.user_id === user?.user_id
        );
        if (!userExists) {
          accumulator.push(user);
        }
      });

      return accumulator;
    }, []);
    // Filtered Users List
    setTsUsersList(uniqueUsers);
  }, [ticketsStatusData]);

  useEffect(() => {
    // Handle the Homologation Status
    const totalTicketsCount = ticketsStatusList?.length || 0;

    let resolvedTicketsCount =
      ticketsStatusList?.filter((ts) =>
        resolvedTicketsStatus?.includes(Number(ts.ticket_status))
      ).length || 0;

    updateHomologationStatus({
      pending: totalTicketsCount - resolvedTicketsCount,
      resolved: resolvedTicketsCount,
      total: totalTicketsCount,
    });

    // Handle the Tickets Status
    updateTicketsStatus(ticketsStatusList);

    // Handle the User Tickets Status
    updateUserTicketsStatus(ticketsStatusList);
  }, [ticketsStatusList]);

  useEffect(() => {
    let filteredVfOptionsList = vehicleFunctionList?.map((vf) => {
      return {
        value: vf.id,
        label: vf.name,
      };
    });
    // Add the "All" option at the top
    filteredVfOptionsList = [
      { value: 0, label: "All" },
      ...filteredVfOptionsList,
    ];

    // Homologation Status vfs
    setHsVfOptionsList(filteredVfOptionsList);
    // Tickets Status vfs
    setTsVfOptionsList(filteredVfOptionsList);
  }, [vehicleFunctionList]);

  useEffect(() => {
    // Filter user list
    let filteredUsersList = tsUsersList?.map((us) => {
      return {
        value: us?.user_id,
        label: `${us?.name} - ${us?.email}`,
        // label: `${us?.name} - ${us?.email} - ${us?.role_id}`,
      };
    });
    // Add the "All" option at the top
    filteredUsersList = [{ value: 0, label: "All" }, ...filteredUsersList];
    setUltUsersOptionsList(filteredUsersList);
  }, [tsUsersList]);

  useEffect(() => {
    // Homologation Status
    let filteredTicketsStatusList = selectedHsVf
      ? ticketsStatusList?.filter((hs) =>
          hs?.vf_ids?.includes(String(selectedHsVf))
        )
      : ticketsStatusList;

    // Handle the Homologation Status
    const totalTicketsCount = filteredTicketsStatusList?.length || 0;

    let resolvedTicketsCount =
      filteredTicketsStatusList?.filter((ts) =>
        resolvedTicketsStatus?.includes(Number(ts.ticket_status))
      ).length || 0;

    updateHomologationStatus({
      pending: totalTicketsCount - resolvedTicketsCount,
      resolved: resolvedTicketsCount,
      total: totalTicketsCount,
    });
  }, [selectedHsVf]);

  useEffect(() => {
    // Tickets Status
    let filteredTicketsStatusList = selectedTsVf
      ? ticketsStatusList?.filter((ts) =>
          ts?.vf_ids?.includes(String(selectedTsVf))
        )
      : ticketsStatusList;

    // Handle the Tickets Status
    updateTicketsStatus(filteredTicketsStatusList);
  }, [selectedTsVf]);

  useEffect(() => {
    // User Level Tickets
    let filteredTicketsStatusList = selectedUltUser
      ? ticketsStatusList?.filter(
          (ult) =>
            ult?.oem_user_ids?.includes(String(selectedUltUser)) ||
            ult?.tech_service_user_ids?.includes(String(selectedUltUser))
        )
      : ticketsStatusList;

    // Handle the User Tickets Status
    updateUserTicketsStatus(filteredTicketsStatusList);
  }, [selectedUltUser]);

  useEffect(() => {
    customConsole({ ticketsStatusData });
    customConsole({ ticketsStatusList });
    customConsole({ vehicleFunctionList });
    customConsole({ tsUsersList });
    customConsole({ selectedHsVf });
  }, [
    ticketsStatusData,
    ticketsStatusList,
    vehicleFunctionList,
    tsUsersList,
    selectedHsVf,
  ]);

  // ----------------------Bharathan-----------------------------

  const [activeTab, setActiveTab] = useState(0);
  const [ticketTableRowsData, setTicketTableRowsData] = useState([]);
  const [eeCTableRowsData, setEECTableRowsData] = useState([]);
  const [swMTableRowsData, setSWMTableRowsData] = useState([]);
  const [statusDetails, setStatusDetails] = useState([]);
  const [recentActivitiesDetails, setRecentActivitiesDetails] = useState([]);
  const [ticketDetails, setTicketDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(5); // Countdown timer in seconds
  const [intervalId, setIntervalId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [currentTicketId, setCurrentTicketId] = useState(null);
  const [userDetails, setUserDetails] = useState([]);
  const [legalDialogData, setLegalDialogData] = useState(null);
  const [isLegalDialogOpen, setIsLegalDialogOpen] = useState(false);

  const getDefaultValue = (arr) => (arr && arr.length > 0 ? arr[0] : 0);

  const project = getDefaultValue(statusDetails?.project_details);
  const swm = getDefaultValue(statusDetails?.swm_details);
  const ee = getDefaultValue(statusDetails?.ee_details);
  const intr = getDefaultValue(statusDetails?.intr_details);
  const deps = getDefaultValue(statusDetails?.dependencies_details);
  const selectedProjectId = selectedProjectDetails?.project_id;

  const linkModuleType = { SWM: 1, EE: 2 };


  useEffect(() => {
    // Get the tickets status
    if (selectedProjectDetails?.project_id) {
      getTicketDetails(Number(selectedProjectDetails?.project_id));
      getRecentActivitiesDetails(Number(selectedProjectDetails?.project_id));
      getStatusDetails(Number(selectedProjectDetails?.project_id));
    }
  }, [selectedProjectDetails]);

  const getTicketDetailsTU = async (projectId) => {
    // console.log(projectId);
    let apiDetails = dashboardAction.get_tickets;
    delete apiDetails.action_type;
    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
      },
      show_toast: false,
    });
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          // project_id: projectId,
        },
        show_toast: false,
      })
    );
    if (resp.error) {
      setTicketDetails([]);
    } else {
      setTicketDetails(resp.data);
    }
  };

  useEffect(() => {
    // Get the tickets status
    getTicketDetailsTU();
  }, []);

  const getTicketDetails = async (projectId) => {
    // console.log(projectId);
    let apiDetails = dashboardAction.get_tickets;
    delete apiDetails.action_type;
    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
      },
      show_toast: false,
    });
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
        },
        show_toast: false,
      })
    );
    if (resp.error) {
      setTicketDetails([]);
    } else {
      setTicketDetails(resp.data);
    }
  };

  const getStatusDetails = async (projectId) => {
    // console.log(projectId);
    let apiDetails = dashboardAction.get_dash_board_status;
    delete apiDetails.action_type;
    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
      },
      show_toast: false,
    });
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
        },
        show_toast: false,
      })
    );
    if (resp.error) {
      setStatusDetails([]);
    } else {
      setStatusDetails(resp.data);
    }
  };

  const getRecentActivitiesDetails = async (projectId) => {

    let apiDetails = dashboardAction.get_dash_board_activities;
    delete apiDetails.action_type;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
      },
      show_toast: false,
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
        },
        show_toast: false,
      })
    );
    if (resp.error) {
      setRecentActivitiesDetails({});
    } else {
      setRecentActivitiesDetails(resp.data);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const eeComponentDetails = recentActivitiesDetails.eeComponentDetails;
  const swmDetails = recentActivitiesDetails.swmDetails;

  useEffect(() => {
    if (ticketDetails) {
      const filteredTicketList = ticketDetails
        ?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        ?.slice(0, 10)
        ?.map((item, index) => ({
          id: index + 1,
          intr_id: item?.intr_id,
          tech_service_users: item?.tech_service_users,
          oem_users: item?.oem_users,
          users: item?.tech_service_users || item?.oem_users,
          ticket_id: item?.ticket_id,
          status: item?.status,
          ls_details: item?.ls_details,
          lr_details: item?.lr_details,
          swm_details: item?.swm_details,
          ee_details: item?.ee_details,
          description: item?.description,
          project_status: item?.project_details?.project_id || item?.status,
          created_by: item?.created_by,
          updated_at: item?.updated_at,
          updated_by: item?.updated_by,
          allData: item,
        }));

      setTicketTableRowsData(filteredTicketList);
    }
  }, [ticketDetails]);

  useEffect(() => {
    if (eeComponentDetails) {
      const filteredeeComponentDetails = eeComponentDetails
        ?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        ?.slice(0, 10)
        ?.map((item) => ({
          id: item?.ee_id,
          assembly_number: item?.assembly_number,
          supplier_name: item?.supplier_name,
          description: item?.description,
          version: item?.version,
          created_by: item?.created_by,
          updated_at: item?.updated_at,
          allData: item,
        }));

      setEECTableRowsData(filteredeeComponentDetails);
    }
  }, [eeComponentDetails]);

  useEffect(() => {
    if (swmDetails) {
      const filteredswmDetails = swmDetails
        ?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        ?.slice(0, 10)
        ?.map((item) => ({
          id: item?.swm_id,
          name: item?.name,
          description: item?.description,
          version: item?.version,
          vehicle_functions: item?.vehicle_functions,
          created_by: item?.created_by,
          allData: item,
        }));

      setSWMTableRowsData(filteredswmDetails);
    }
  }, [swmDetails]);

  const getTicketStatusName = (statusCode) => {
    return ticketStatus[statusCode] || "-";
  };

  useEffect(() => {
    if (open) {
      // Start the countdown timer when the dialog opens
      const id = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(id);
            handleRedirect();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      setIntervalId(id);

      return () => {
        clearInterval(id);
      };
    }
  }, [open]);

  const LinkButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    padding: 0,
    minWidth: "auto",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  }));

  const CountdownTimer = styled(Typography)(({ theme }) => ({
    backgroundColor: "green",
    color: "white",
    borderRadius: "30px",
    textAlign: "center",
    width: "100px",
    cursor: "pointer",
  }));

  const handleClickOpen = (ticket_id) => {
    setCurrentTicketId(ticket_id);
    setOpen(true);
    setTimer(5);
  };

  const handleClose = () => {
    setOpen(false);
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  const handleRedirect = () => {
    setOpen(false);
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    if (currentTicketId) {
      window.open(
        `https://ven07074.service-now.com/incident.do?sysparm_query=number=${currentTicketId}`,
        "_blank"
      );
    }
  };

  //Function to handle Toggle drawer open/closed
  const toggleDialog = (newOpen) => () => {
    setIsDialogOpen(newOpen);
  };

  //Function to handle EE Toggle drawer open/closed
  const toggleEEDialog = (newOpen) => () => {
    setIsEEDialogOpen(newOpen);
  };

  //Function to handle Toggle Legal Dialog open/closed
  const toggleLegalDialog = (newOpen) => () => {
    setIsLegalDialogOpen(newOpen);
  };

  // Handle button click to open drawer with ticket details
  const handleViewButtonClick = (item) => {
    const modules = item?.dependencies?.software_modules || [];
    const vehicleFunctions = item?.swm_details?.vehicle_functions;

    // Map the modules to include both module details and their corresponding ee_details
    const items2 = modules.map((module) => ({
      swm_id: module?.swm_id,
      name: module?.name,
      version: module?.version,
      description: module?.description,
      ee_details: module?.ee_details || [],
    }));

    const eeDetails = modules.flatMap((module) => module?.ee_details) || [];

    setDialogData({
      heading1: `Vehicle Functions`,
      items1:
        vehicleFunctions?.map((vehicleFunction) => ({
          name: vehicleFunction?.name,
          abbreviation: vehicleFunction?.abbreviation,
        })) || [],
      heading2: `Dependent Software Modules`,
      items2: items2, // Use the combined items
      heading3: `EE Details`,
      items3: eeDetails.map((ee) => ({
        ee_id: ee?.ee_id,
        assembly_number: ee?.assembly_number,
        description: ee?.description,
        supplier_name: ee?.supplier_name,
        version: ee?.version,
      })),
    });

    toggleDialog(true)();
  };

  const [userDetailsMap, setUserDetailsMap] = useState(new Map());
  const [usersDetail, setUsersDetail] = useState(new Map());

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const apiDetails = userManagementApis?.get_users_list_pa;
        delete apiDetails.action_type;
        const resp = await dispatch(
          HandleApiActions({
            ...apiDetails,
            show_toast: false,
          })
        );

        if (!resp.error) {
          // Create a map for quick lookup
          const users = resp?.data;
          const userMap = new Map(
            users.map((user) => [user?.user_id, user?.fullname])
          );
          setUserDetailsMap(userMap);
          setUsersDetail(users);
        }
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  // Function to get user full name by ID
  const getUserFullNameById = (userId) => {
    return userDetailsMap.get(userId) || userId;
  };

  // Define default user details in case user details are not found
  const defaultUsersDetails = { fullname: "User removed" };

  // Retrieve user details by ID or fallback to default
  const getUsersDetailsById = (userId) => {
    return userDetailsMap?.get(userId || defaultUsersDetails);
  };

  const handleSWMViewButtonClick = (item) => {
    const modules = item?.dependencies?.software_modules || [];
    const vehicleFunctions = item?.swm_details?.vehicle_functions;

    // Map the modules to include both module details and their corresponding ee_details
    const items2 = modules?.map((module) => ({
      swm_id: module?.swm_id,
      name: module?.name,
      version: module?.version,
      description: module?.description,
      ee_details: module?.ee_details || [], // Include ee_details in each module
    }));

    const eeDetails = modules?.flatMap((module) => module?.ee_details) || [];

    setDialogData({
      heading1: `Vehicle Functions`,
      items1:
        vehicleFunctions?.map((vehicleFunction) => ({
          name: vehicleFunction?.name,
          abbreviation: vehicleFunction?.abbreviation,
        })) || [],
      heading2: `Dependent Software Modules`,
      items2: items2, // Use the combined items
      heading3: `EE Details`,
      items3: eeDetails.map((ee) => ({
        ee_id: ee?.ee_id,
        assembly_number: ee?.assembly_number,
        description: ee?.description,
        supplier_name: ee?.supplier_name,
        version: ee?.version,
      })),
    });

    toggleDialog(true)();
  };

  // Handle button click to open drawer with ticket details
  const handleEEViewButtonClick = (item) => {
    const modules = item?.dependencies?.ee_components || [];
    const vehicleSystems = item?.ee_details?.vehicle_systems;

    setEEDialogData({
      heading1: `Vehicle Systems`,
      items1:
        vehicleSystems?.map((vs) => ({
          name: vs?.name,
          abbreviation: vs?.abbreviation,
        })) || [],
      heading2: `Dependent EE Components`,
      items2: modules.map((ee) => ({
        ee_id: ee?.ee_id,
        assembly_number: ee?.assembly_number,
        description: ee?.description,
        supplier_name: ee?.supplier_name,
        version: ee?.version,
      })),
    });

    toggleEEDialog(true)();
  };

  const handleLEGALViewButtonClick = (item) => {
    const lr_details = item?.lr_details || {};
    const ls_details = item?.ls_details || {};

    // Directly map lr_details to items2
    const items2 = [
      {
        lr_id: lr_details.lr_id,
        chapter: lr_details.chapter,
        description: lr_details.description,
        req_number: lr_details.req_number,
      },
    ];

    // Create items3 from ls_details
    const items3 = [
      {
        ls_id: ls_details.ls_id,
        standard_number: ls_details.standard_number,
        name: ls_details.name,
        revision: ls_details.revision,
        description: ls_details.description,
        chapters: ls_details.chapters,
      },
    ];

    setLegalDialogData({
      heading2: `Legal Requirement`,
      items2: items2,
      items3: items3, 
    });

    toggleLegalDialog(true)();
  };

  const TICKET_COLUMNS = [
    {
      field: "intr_id",
      headerName: "INTERSECTION ID",
      headerAlign: "center",
      width: 140,
      renderCell: (params) => (
        <Box sx={{ textAlign: "center", fontSize: "12px" }}>
          {params?.value || "-"}
        </Box>
      ),
    },
    {
      field: "ticket_id",
      headerName: "TICKET ID",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <Box sx={{ textAlign: "center" }}>
          <LinkButton
            onClick={() => handleClickOpen(params.value)}
            sx={{ fontSize: "12px" }}
          >
            {params?.value}
          </LinkButton>

          <Dialog
            open={open}
            onClose={handleClose}
            anchor="right"
            BackdropProps={{
              style: { backgroundColor: "transparent" },
            }}
            PaperProps={{
              style: {
                width: "400px",
                height: "180px",
                borderRadius: "15px",
              },
            }}
          >
            <DialogTitle>
              <IconButton
                onClick={handleClose}
                sx={styles.closeButton}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                Redirecting to ServiceNow <br />
                Ticketing tool!
              </Typography>

              <CountdownTimer
                onClick={handleRedirect}
                variant="h6"
                sx={{ mt: "20px", ml: "120px" }}
              >
                In ({timer})s
              </CountdownTimer>
            </DialogContent>
          </Dialog>
        </Box>
      ),
    },
    {
      field: "project_status",
      headerName: "STATUS",
      headerAlign: "center",
      width: 180,
      renderCell: (params) => {
        const projectId = params?.row?.project_details?.project_id;
        const status = params?.row?.status;

        return (
          <Link to={`/dashboard/type-approval?project_id=${selectedProjectId}&status=${status}`}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography sx={{ textTransform: "uppercase" }}>
                {getTicketStatusName(status || "-")}
              </Typography>
            </Box>
          </Link>
        );
      },
    },
    {
      field: "updated_by",
      headerName: "CHANGED BY",
      headerAlign: "center",
      width: 140,
      renderCell: (params) => {
        const userId = params?.value;
        const userName = getUserFullNameById(userId);
        return <Box sx={{ textAlign: "center" }}>{userName || userId}</Box>;
      },
    },
    {
      field: "reason",
      headerName: "REASON",
      headerAlign: "center",
      width: 140,
    },
    {
      field: "swm_details",
      headerName: "SOFTWARE MODULE",
      headerAlign: "left",
      width: 200,
      renderCell: (params) => {
        const { swm_details } = params?.row;
        return (
          <Box sx={{ textAlign: "left", fontSize: "12px" }}>
            <Box>
              <strong>Name:</strong> {swm_details?.name || "-"}
            </Box>
            <Box>
              <strong>Version:</strong> {swm_details?.version || "-"}
            </Box>
            <Box>
              <strong>Description:</strong> {swm_details?.description || "-"}
            </Box>
            {swm_details?.name ? (
            <Box sx={{ textAlign: "left", marginTop: 2 }}>
              <Button
                variant="contained"
                onClick={() => handleSWMViewButtonClick(params?.row?.allData)}
                sx={{ ...appTheme.commonBtnStyle, fontSize: "10px" }}
                size="small"
              >
                View more{" >>"}
              </Button>
            </Box>
            ) : null}
          </Box>
        );
      },
    },
    {
      field: "ls_lr_details",
      headerName: "LEGAL DETAILS",
      headerAlign: "left",
      width: 250,

      renderCell: (params) => {
        const { lr_details, ls_details } = params?.row;
        return (
          <Box sx={{ textAlign: "left", fontSize: "12px" }}>
            <Box>
              <strong>Chapter:</strong> {lr_details?.chapter || "-"}
            </Box>
            <Box>
              <strong>Requirement Number:</strong>{" "}
              {lr_details?.req_number || "-"}
            </Box>
            <Box>
              <strong>Standard Number:</strong>{" "}
              {ls_details?.standard_number || "-"}
            </Box>
            <Box>
              <strong>Revision:</strong> {ls_details?.revision || "-"}
            </Box>
            <Box sx={{ textAlign: "left", marginTop: 1, fontSize: "10px" }}>
              <Button
                variant="contained"
                onClick={() => handleLEGALViewButtonClick(params?.row?.allData)}
                sx={{ ...appTheme.commonBtnStyle, fontSize: "10px" }}
                size="small"
              >
                View more{" >>"}
              </Button>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "ee_details",
      headerName: "EE COMPONENT",
      headerAlign: "left",
      width: 200,
      renderCell: (params) => {
        const { ee_details } = params?.row;
        return (
          <Box sx={{ textAlign: "left", fontSize: "12px" }}>
            <Box>
              <strong>Supplier Name:</strong> {ee_details?.supplier_name || "-"}
            </Box>
            <Box>
              <strong>Assembly Number:</strong>{" "}
              {ee_details?.assembly_number || "-"}
            </Box>
            <Box>
              <strong>Version:</strong> {ee_details?.version || "-"}
            </Box>
            <Box>
              <strong>Description:</strong> {ee_details?.description || "-"}
            </Box>
            {params?.row?.allData?.dependencies?.ee_components ? (
              <Box sx={{ textAlign: "left", marginTop: 2, fontSize: "10px" }}>
                <Button
                  variant="contained"
                  onClick={() => handleEEViewButtonClick(params?.row?.allData)}
                  sx={{ ...appTheme.commonBtnStyle, fontSize: "10px" }}
                  size="small"
                >
                  View more{" >>"}
                </Button>
              </Box>
            ) : null}
          </Box>
        );
      },
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      headerAlign: "center",
      width: 250,
      renderCell: (params) => <DescriptionCell value={params?.value || "-"} />,
    },
    {
      field: "created_by",
      headerName: "OWNER",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => (
        <Box sx={{ textAlign: "center", fontSize: "12px" }}>
          {getUserFullNameById(params?.value || "-")}
        </Box>
      ),
    },
  ];

  const EE_COLUMNS = [
    {
      field: "assembly_number",
      headerName: "ASSEMBLY NUMBER",
      width: 140,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ fontSize: "12px" }}>{params?.value || "-"}</Box>
      ),
    },
    {
      field: "supplier_name",
      headerName: "SUPPLIER NAME",
      width: 140,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ fontSize: "12px" }}>{params?.value || "-"}</Box>
      ),
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      width: 140,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ fontSize: "12px" }}>{params?.value || "-"}</Box>
      ),
    },
    {
      field: "version",
      headerName: "VERSION",
      width: 140,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ fontSize: "12px" }}>{params?.value || "-"}</Box>
      ),
    },
    {
      field: "created_by",
      headerName: "OWNER",
      width: 140,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ fontSize: "12px" }}>
          {getUserFullNameById(params?.value || "-")}
        </Box>
      ),
    },
  ];

  const SWM_COLUMNS = [
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ fontSize: "12px" }}>{params?.value || "-"}</Box>
      ),
    },
    {
      field: "version",
      headerName: "VERSION",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ fontSize: "12px" }}>{params?.value || "-"}</Box>
      ),
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ fontSize: "12px" }}>{params?.value || "-"}</Box>
      ),
    },
    {
      field: "vehicle_functions",
      headerName: "VEHICLE FUNCTIONS",
      flex: 1,
      renderCell: (params) => {
        const vehicleFunctions = params?.row?.vehicle_functions || [];
        const defaultValue =
          vehicleFunctions.length > 0 ? vehicleFunctions[0].name : "";

        return (
          <FormControl fullWidth>
            {/* Vehicle Functions Section */}
            {vehicleFunctions.length > 0 ? (
              <>
                <Select
                  value={defaultValue}
                  displayEmpty
                  sx={{ height: "30px", fontSize: "12px" }}
                  renderValue={(selected) =>
                    selected ? selected : "VEHICLE FUNCTIONS"
                  }
                >
                  {vehicleFunctions.map((func, index) => (
                    <MenuItem
                      sx={{ fontSize: "12px" }}
                      key={index}
                      value={func.name}
                    >
                      {func.name} ({func.abbreviation})
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : (
              <Typography>No Vehicle Functions Available</Typography>
            )}
          </FormControl>
        );
      },
    },

    {
      field: "created_by",
      headerName: "OWNER",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ fontSize: "12px" }}>
          {getUserFullNameById(params?.value || "-")}
        </Box>
      ),
    },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <Grid container spacing={2}>
            {/* Placeholder content for Tickets tab */}
            <Grid item xs={12}>
              <Card
                sx={{
                  width: "100%",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  boxShadow: "3",
                }}
              >
                {/* Container for the Table */}
                <Box sx={styles.box}>
                  {ticketTableRowsData && ticketTableRowsData.length > 0 ? (
                    <Paper sx={styles.paper}>
                      <CustomDataGridTableB
                        tableRowsData={ticketTableRowsData}
                        columns={TICKET_COLUMNS}
                        isMultiLine={true}
                        handleTableRowClick={() => null}
                        disableDensity={true}
                      />
                    </Paper>
                  ) : (
                    <Typography sx={{ padding: 2, textAlign: "center" }}>
                      No tickets available.
                    </Typography>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            {/* Placeholder content for eeComponent tab */}
            <Grid item xs={12}>
              <Card
                sx={{
                  width: "100%",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  boxShadow: "3",
                }}
              >
                {/* Container for the Table */}
                <Box sx={styles.box}>
                  {eeCTableRowsData && eeCTableRowsData.length > 0 ? (
                    <Paper sx={styles.paper}>
                      <CustomDataGridTableB
                        tableRowsData={eeCTableRowsData}
                        columns={EE_COLUMNS}
                        isMultiLine={true}
                        handleTableRowClick={() => null}
                      />
                    </Paper>
                  ) : (
                    <Typography sx={{ padding: 2, textAlign: "center" }}>
                      EE Component Not available.
                    </Typography>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            {/* Placeholder content for Software Modules tab */}
            <Grid item xs={12}>
              <Card
                sx={{
                  width: "100%",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  boxShadow: "3",
                }}
              >
                {/* Container for the Table */}
                <Box sx={styles.box}>
                  {swMTableRowsData && swMTableRowsData.length > 0 ? (
                    <Paper sx={styles.paper}>
                      <CustomDataGridTableB
                        tableRowsData={swMTableRowsData}
                        columns={SWM_COLUMNS}
                        isMultiLine={true}
                        handleTableRowClick={() => null}
                      />
                    </Paper>
                  ) : (
                    <Typography sx={{ padding: 2, textAlign: "center" }}>
                      Sofware Modules Not available.
                    </Typography>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };



  return (
    <Box
      sx={{
        px: 2,
        pt: 1,
        pb: 12,
        // border: "1px solid black",
      }}
    >
      <Box sx={styles.topContainer}>
        <SearchSelectTextField
          size={"small"}
          label={"Search Project"}
          placeholder={"Search Project"}
          noOptionsText={"No project found"}
          optionsList={projectOptionsList}
          selectedValue={selectedProject}
          setSelectedValue={handleProjectChange}
          txtFldStyle={styles.searchSelectTextField}
        />
      </Box>
      <Grid container spacing={2}>
        {/* Homologation status */}
        <Grid item xs={12} md={4}>
          <Box sx={styles.chartsContainer}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Typography variant="h6">Homologation Status</Typography>

              <CustomFilterIconN
                anchorEl={anchorElHsFilter}
                open={openHsFilter}
                handleFilterIconClick={handleHsFilterIconClick}
                handleClose={handleHsFilterIconClose}
                size={"small"}
                label={"Select Vehicle Fun."}
                variant={"standard"}
                optionsList={hsVfOptionsList}
                selectedValue={selectedHsVf}
                handleOnChange={(e) => setSelectedHsVf(e.target.value)}
                sltFldStyle={styles.selectTxtStyle}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "270px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {totalHsTickets ? (
                <Chart
                  chartType="PieChart"
                  width="100%"
                  height="270px"
                  data={homologationStatus}
                  options={{
                    chartArea: {},
                    pieHole: 0.47,
                    is3D: false,
                    legend: {
                      position: "bottom",
                      alignment: "center",
                    },
                    colors: [
                      "#007CB0", // Total
                      "#26890D", // Completed
                    ],
                    slices: {
                      0: { visibleInLegend: true }, // Total
                      1: { visibleInLegend: true }, // Completed
                    },
                  }}
                />
              ) : (
                <Typography>No tickets found</Typography>
              )}
            </Box>
          </Box>
        </Grid>

        {/* Tickets status */}
        <Grid item xs={12} md={4}>
          <Box sx={styles.chartsContainer}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Typography variant="h6">Tickets Status</Typography>

              <CustomFilterIconN
                anchorEl={anchorElTsFilter}
                open={openTsFilter}
                handleFilterIconClick={handleTsFilterIconClick}
                handleClose={handleTsFilterIconClose}
                size={"small"}
                label={"Select Vehicle Fun."}
                variant={"standard"}
                optionsList={tsVfOptionsList}
                selectedValue={selectedTsVf}
                handleOnChange={(e) => setSelectedTsVf(e.target.value)}
                sltFldStyle={styles.selectTxtStyle}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "270px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {totalVfTickets ? (
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height="270px"
                  data={ticketsStatus}
                  // options={tsOptions}
                  options={{
                    legend: { position: "none" },
                    chartArea: {
                      // width: "70%",
                      height: "75%",
                      left: 115,
                    },
                    vAxis: {
                      // title: "Status",
                      viewWindow: {
                        min: 0, // Ensures the minimum value on the Y-axis is 0
                        ...(!totalVfTickets && { max: 1 }),
                      },
                    },
                    hAxis: {
                      // title: "Count",
                      textStyle: {
                        // fontSize: 10,
                      },
                      // slantedText: false,
                    },
                  }}
                />
              ) : (
                <Typography>No tickets found</Typography>
              )}
            </Box>
          </Box>
        </Grid>

        {/* User level tickets */}
        <Grid item xs={12} md={4}>
          <Box sx={styles.chartsContainer}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Typography variant="h6">User Level Tickets</Typography>

              <CustomFilterIconN
                anchorEl={anchorElUltFilter}
                open={openUltFilter}
                handleFilterIconClick={handleUltFilterIconClick}
                handleClose={handleUltFilterIconClose}
                size={"small"}
                label={"Select User"}
                variant={"standard"}
                optionsList={ultUsersOptionsList}
                selectedValue={selectedUltUser}
                handleOnChange={(e) => setSelectedUltUser(e.target.value)}
                sltFldStyle={styles.selectTxtStyle}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "270px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {totalUserTickets ? (
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height="270px"
                  data={userLevelTickets}
                  // options={ultOptions}
                  options={{
                    legend: { position: "none" },
                    chartArea: {
                      // width: "70%",
                      height: "75%",
                      left: 115,
                    },
                    vAxis: {
                      // title: "Status",
                      viewWindow: {
                        min: 0, // Ensures the minimum value on the Y-axis is 0
                        ...(!totalUserTickets && { max: 1 }),
                      },
                    },
                    hAxis: {
                      // title: "Count",
                      textStyle: {
                        // fontSize: 10,
                      },
                      // slantedText: false,
                    },
                  }}
                />
              ) : (
                <Typography>No tickets found</Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* ----------------------Bharathan------------------------------- */}
      <Grid container spacing={2} sx={{ mt: "3px" }}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              p: 2,
              border: "1px solid #ddd",
              borderRadius: "8px",
              boxShadow: "3",
            }}
          >
            <Typography
              fontWeight="bold"
              sx={{ fontSize: "20px" }}
              gutterBottom
            >
              Recent Activities
            </Typography>
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                borderRadius: "20px",
                boxShadow: "3",
              }}
            >
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // height: "10px",
                  p: 0.5,
                  // "& .MuiTabs-flexContainer": {
                  //   justifyContent: "space-around",
                  // },
                  // "& .MuiTab-root": {
                  //   flex: 1,
                  //   textAlign: "center",
                  //   //minWidth: 0,  // Ensures tabs can shrink if needed
                  // },
                  "& .MuiTabs-indicator": {
                    display: "none", // Hides the underline/indicator
                  },
                }}
              >
                <Tab
                  label="Intersections / Tickets"
                  sx={{
                    flex: 1,
                    textAlign: "center",
                    border: 1,
                    borderColor: "divider",
                    borderRadius: "20px",
                    mr: 1,
                    boxShadow: "3",
                    mb: 0.5,
                    fontSize: "14px",
                    height: "10px",
                  }}
                />
                <Tab
                  label="ee Component"
                  sx={{
                    flex: 1,
                    textAlign: "center",
                    border: 1,
                    borderColor: "divider",
                    borderRadius: "20px",
                    mr: 1,
                    boxShadow: "3",
                    mb: 0.5,
                    fontSize: "14px",
                  }}
                />
                <Tab
                  label="Software Modules"
                  sx={{
                    flex: 1,
                    textAlign: "center",
                    border: 1,
                    borderColor: "divider",
                    borderRadius: "20px",
                    boxShadow: "3",
                    mb: 0.5,
                    fontSize: "14px",
                  }}
                />
              </Tabs>
            </Box>
            <Grid item xs={12} md={12} sx={{ paddingTop: 2 }}>
              {renderTabContent()}
            </Grid>
          </Box>
        </Grid>

        {/* Project Configuration Status Data handles here */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              p: 1,
              border: "1px solid #ddd",
              borderRadius: "8px",
              boxShadow: "3",
            }}
          >
            <Typography
              fontWeight="bold"
              sx={{ fontSize: "20px" }}
              gutterBottom
            >
              Project Configuration Status
            </Typography>

            <Card
              sx={{
                mb: 2,
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxShadow: "3",
              }}
            >
              <Typography
                fontWeight="bold"
                sx={{ fontSize: "18px", mt: "5px" }}
              >
                Linking
              </Typography>
              <CardContent sx={{ boxShadow: "3" }}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={4}
                    sx={{ border: "1px solid #ddd", p: 1, height: "60px" }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ fontSize: "13px" }}
                    >
                      Type
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ border: "1px solid #ddd", p: 1, height: "60px" }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ fontSize: "13px" }}
                    >
                      Linked SWMs
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ border: "1px solid #ddd", p: 1, height: "60px" }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ fontSize: "13px" }}
                    >
                      Un-linked SWMs
                    </Typography>
                  </Grid>

                  {/* Row 1 */}
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    <Typography fontWeight="bold" sx={{ fontSize: "12px" }}>
                      Intersections
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    {intr?.linked > 0 ? (
                      <Link
                        to={`/dashboard/type-approval?project_id=${selectedProjectId}`}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {intr.linked}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        0
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    {intr?.un_linked > 0 ? (
                      <Link
                        to={`/dashboard/type-approval?project_id=${selectedProjectId}`}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {intr.un_linked}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        0
                      </Typography>
                    )}
                  </Grid>

                  {/* Row 2 */}
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    <Typography fontWeight="bold" sx={{ fontSize: "12px" }}>
                      Dependencies
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    {deps?.linked > 0 ? (
                      <Link
                        to={`/dashboard/labcar?project_id=${selectedProjectId}&linkSoftwareModulesScreen=${true}&linkModuleType=${linkModuleType?.SWM}`}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {deps.linked}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        0
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    {deps?.un_linked > 0 ? (
                      <Link
                        to={`/dashboard/labcar?project_id=${selectedProjectId}&linkSoftwareModulesScreen=${true}&linkModuleType=${linkModuleType?.SWM}`}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {deps.un_linked}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        0
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card
              sx={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxShadow: "3",
              }}
            >
              <Typography
                fontWeight="bold"
                sx={{ fontSize: "18px", mt: "5px" }}
              >
                Users
              </Typography>
              <CardContent sx={{ boxShadow: "3" }}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={4}
                    sx={{ border: "1px solid #ddd", p: 1, height: "60px" }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ fontSize: "13px" }}
                    >
                      Type
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ border: "1px solid #ddd", p: 1, height: "60px" }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ fontSize: "13px" }}
                    >
                      Owners & Co-Owners
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ border: "1px solid #ddd", p: 1, height: "60px" }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ fontSize: "13px" }}
                    >
                      Editors & Viewers
                    </Typography>
                  </Grid>

                  {/* Row 1 */}
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    <Typography fontWeight="bold" sx={{ fontSize: "12px" }}>
                      Project
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    {project.owners + project.co_owners > 0 ? (
                      <Link
                        to={`/dashboard/user-management?project_id=${selectedProjectId}`}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {project?.owners + project?.co_owners || 0}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        0
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    {project?.editors + project?.viewers > 0 ? (
                      <Link
                        to={`/dashboard/user-management?project_id=${selectedProjectId}`}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {project?.editors + project?.viewers || 0}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        0
                      </Typography>
                    )}
                  </Grid>

                  {/* Row 2 */}
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    <Typography fontWeight="bold" sx={{ fontSize: "12px" }}>
                      ECUs
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    {ee?.owners + ee?.co_owners > 0 ? (
                      <Link
                        to={`/dashboard/user-management?project_id=${selectedProjectId}`}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {ee?.owners + ee?.co_owners || 0}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        0
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    {ee?.editors + ee?.viewers > 0 ? (
                      <Link
                        to={`/dashboard/user-management?project_id=${selectedProjectId}`}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {ee?.editors + ee?.viewers || 0}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        0
                      </Typography>
                    )}
                  </Grid>

                  {/* Row 3 */}
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    <Typography fontWeight="bold" sx={{ fontSize: "12px" }}>
                      SWMs
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    {swm?.owners + swm?.co_owners > 0 ? (
                      <Link
                        to={`/dashboard/user-management?project_id=${selectedProjectId}`}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {swm?.owners + swm?.co_owners || 0}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        0
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} sx={{ border: "1px solid #ddd", p: 1 }}>
                    {swm?.editors + swm?.viewers > 0 ? (
                      <Link
                        to={`/dashboard/user-management?project_id=${selectedProjectId}`}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {swm?.editors + swm?.viewers || 0}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        0
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>

      {/* Container for Dialog to view more about ticket */}
      <Dialog
        open={isDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleDialog(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: "350px",
            boxSizing: "border-box",
            padding: 0,
          },
        }}
      >
        <Box sx={styles.drawerContainer}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Software Module
          </Typography>
          <Divider sx={styles.divider} />

          {/* Close Button */}
          <IconButton onClick={toggleDialog(false)} sx={styles.closeButton}>
            <CloseIcon />
          </IconButton>

          {/* Vehicle Functions */}
          <Box sx={styles.drawerWrap}>
            <Paper sx={styles.paperDrawer}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Vehicle Function
              </Typography>
              <Divider sx={styles.divider} />

              {dialogData?.items1?.map((vehicleFunction, index) => (
                <Paper key={index} sx={styles.cardDrawer}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      display: "flex",
                    }}
                  >
                    <strong>{vehicleFunction.abbreviation}</strong>:{" "}
                    {vehicleFunction.name}
                  </Typography>
                </Paper>
              ))}

              {dialogData?.items1?.length <= 0 && (
                <Typography sx={{ textAlign: "center" }}>
                  No data found.
                </Typography>
              )}
            </Paper>
          </Box>

          {/* Software Modules */}
          <Box sx={styles.drawerWrap}>
            {dialogData?.heading2 && (
              <Paper sx={styles.paperDrawer}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: 1,
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Dependent Software Modules
                </Typography>
                <Divider sx={styles.divider} />
                <Box>
                  {dialogData?.items2?.map((module, index) => (
                    <Paper key={index} sx={styles.cardDrawer}>
                      <Box
                        sx={{
                          border: "1px solid black",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        {/* Software Modules */}
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: "15px",
                              textDecoration: "underline",
                            }}
                          >
                            Software Module
                          </Typography>
                          <Box sx={{ gap: "10px" }}>
                            {[
                              { header: "ID", value: module?.swm_id },
                              { header: "Name", value: module?.name },
                              { header: "Version", value: module?.version },
                              {
                                header: "Description",
                                value: module?.description,
                              },
                            ].map((det, key) => (
                              <Typography key={key} sx={{ fontSize: "13px" }}>
                                <strong>{det?.header}:</strong> {det?.value}
                              </Typography>
                            ))}
                          </Box>
                        </Box>

                        {/* EE Component */}
                        {module?.ee_details && module.ee_details.length > 0 && (
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: "black",
                                fontSize: "15px",
                                paddingTop: "10px",
                                textDecoration: "underline",
                              }}
                            >
                              EE Component
                            </Typography>
                            {module.ee_details.map((ee, eeIndex) => (
                              <Box key={eeIndex} sx={{ gap: "10px" }}>
                                {[
                                  { header: "Name", value: ee?.description },
                                  { header: "Version", value: ee?.version },
                                  {
                                    header: "Assembly Number",
                                    value: ee?.assembly_number,
                                  },
                                  {
                                    header: "Supplier Name",
                                    value: ee?.supplier_name,
                                  },
                                ].map((det, key) => (
                                  <Typography
                                    key={key}
                                    sx={{ fontSize: "13px" }}
                                  >
                                    <strong>{det?.header}:</strong> {det?.value}
                                  </Typography>
                                ))}
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    </Paper>
                  ))}

                  {dialogData?.items2.length <= 0 && (
                    <Typography sx={{ textAlign: "center" }}>
                      No data found.
                    </Typography>
                  )}
                </Box>
              </Paper>
            )}
          </Box>
        </Box>

        {/* <DialogActions>
    <Button onClick={toggleDialog(false)} sx={{ ...appTheme.commonBtnStyle }}>
      Close
    </Button>
  </DialogActions> */}
      </Dialog>

      {/* Dialog for ee dependencies */}
      <Dialog
        open={isEEDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleEEDialog(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: "350px",
            boxSizing: "border-box",
            padding: 0,
          },
        }}
      >
        <Box sx={styles.drawerContainer}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            EE Components
          </Typography>
          <Divider sx={styles.divider} />

          {/* Close Button */}
          <IconButton onClick={toggleEEDialog(false)} sx={styles.closeButton}>
            <CloseIcon />
          </IconButton>

          {/* System Functions */}
          <Box sx={styles.drawerWrap}>
            <Paper sx={styles.paperDrawer}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                System Functions
              </Typography>
              <Divider sx={styles.divider} />

              {eeDialogData?.items1?.map((vehicleSystem, index) => (
                <Paper key={index} sx={styles.cardDrawer}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      display: "flex",
                    }}
                  >
                    <strong>{vehicleSystem?.abbreviation}</strong>:{" "}
                    {vehicleSystem?.name}
                  </Typography>
                </Paper>
              ))}

              {eeDialogData?.items1?.length <= 0 && (
                <Typography sx={{ textAlign: "center" }}>
                  No data found.
                </Typography>
              )}
            </Paper>
          </Box>

          {/* Software Modules */}
          <Box sx={styles.drawerWrap}>
            {eeDialogData?.heading2 && (
              <Paper sx={styles.paperDrawer}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: 1,
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Dependent EE Components
                </Typography>
                <Divider sx={styles.divider} />
                <Box>
                  {eeDialogData?.items2?.map((module, index) => (
                    <Paper key={index} sx={styles.cardDrawer}>
                      <Box
                        sx={{
                          border: "1px solid black",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        {/* EE Component */}
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: "15px",
                              textDecoration: "underline",
                            }}
                          >
                            EE Component
                          </Typography>
                          <Box sx={{ gap: "10px" }}>
                            {[
                              { header: "Name", value: module?.description },
                              { header: "Version", value: module?.version },
                              {
                                header: "Assembly Number",
                                value: module?.assembly_number,
                              },
                              {
                                header: "Supplier Name",
                                value: module?.supplier_name,
                              },
                            ].map((det, key) => (
                              <Typography key={key} sx={{ fontSize: "13px" }}>
                                <strong>{det?.header}:</strong> {det?.value}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  ))}

                  {eeDialogData?.items2?.length <= 0 && (
                    <Typography sx={{ textAlign: "center" }}>
                      No data found.
                    </Typography>
                  )}
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={isLegalDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleLegalDialog(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: "350px",
            boxSizing: "border-box",
            padding: 0,
          },
        }}
      >
        <Box sx={styles.drawerContainer}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            LEGAL DETAILS
          </Typography>
          <Divider sx={styles.divider} />

          {/* Close Button */}
          <IconButton
            onClick={toggleLegalDialog(false)}
            sx={styles.closeButton}
          >
            <CloseIcon />
          </IconButton>

          {/* Legal Requirements */}
          <Box sx={styles.drawerWrap}>
            {legalDialogData?.heading2 && (
              <Paper sx={styles.paperDrawer}>
                <Box>
                  {legalDialogData?.items2.map((lr, index) => (
                    <Paper key={index} sx={styles.cardDrawer}>
                      <Box
                        sx={{
                          border: "1px solid black",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "black",
                            fontSize: "15px",
                            textDecoration: "underline",
                          }}
                        >
                          Legal Requirements
                        </Typography>
                        <Box sx={{ gap: "10px" }}>
                          {[
                            { header: "ID", value: lr?.lr_id },
                            { header: "Chapter", value: lr?.chapter },
                            {
                              header: "Requirement Number",
                              value: lr?.req_number,
                            },
                            {
                              header: "Description",
                              value: lr?.description,
                            },
                          ].map((det, key) => (
                            <Typography key={key} sx={{ fontSize: "13px" }}>
                              <strong>{det?.header}:</strong> {det?.value}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    </Paper>
                  ))}
                </Box>
              </Paper>
            )}
          </Box>

          {/* Legal Standards */}
          <Box sx={styles.drawerWrap}>
            {legalDialogData?.items3 && (
              <Paper sx={styles.paperDrawer}>
                <Box>
                  {legalDialogData?.items3.map((ls, index) => (
                    <Paper key={index} sx={styles.cardDrawer}>
                      <Box
                        sx={{
                          border: "1px solid black",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "black",
                            fontSize: "15px",
                            textDecoration: "underline",
                          }}
                        >
                          Legal Standard
                        </Typography>
                        <Box sx={{ gap: "10px" }}>
                          {[
                            { header: "ID", value: ls?.ls_id },
                            {
                              header: "Standard Number",
                              value: ls?.standard_number,
                            },
                            { header: "Name", value: ls?.name },
                            { header: "Revision", value: ls?.revision },
                            { header: "Description", value: ls?.description },
                            {
                              header: "Chapters",
                              value: ls?.chapters.join(", "),
                            }, // Display chapters
                          ].map((det, key) => (
                            <Typography key={key} sx={{ fontSize: "13px" }}>
                              <strong>{det?.header}:</strong> {det?.value}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    </Paper>
                  ))}
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export default Home;

//Constants to handle ticket status

const ticketStatus = {
  100: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#000000",
        background: "none",
        color: "#000000",
        fontSize: "12px",
      }}
      label={"NEW"}
      // size="small"
    />
  ),
  101: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"DRAFT"}
      // size="small"
    />
  ),
  200: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"ARTIFACT CONFIRMATION"}
      // size="small"
    />
  ),
  300: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"EVIDENCE SUBMISSION"}
      // size="small"
    />
  ),
  400: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"EVIDENCE REVIEW"}
      // size="small"
    />
  ),
  500: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        background: "none",
        // backgroundColor: "#0076a8",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"TESTING AT TECHNICAL SERVICE"}
      // size="small"
    />
  ),
  600: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#26890D",
        background: "none",
        color: "#26890D",
        fontSize: "12px",
      }}
      label={"RESOLVED"}
      // size="small"
    />
  ),
};

//Function to minimize the length of the description for legal requirement
const ExpandableDescription = ({ description }) => {
  const maxLength = 15;
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to toggle the description view
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const displayText =
    isExpanded || !description || description.length <= maxLength
      ? description
      : `${description.substring(0, maxLength)}...`;

  return (
    <Box sx={{ textAlign: "justify" }}>
      <Typography sx={{ fontSize: "12px" }}>
        {displayText}
        {description && description.length > maxLength && (
          <Button
            onClick={handleToggle}
            sx={{ textTransform: "none", p: 0, fontSize: "12px" }}
          >
            {isExpanded ? "Show Less" : "Show More"}
          </Button>
        )}
      </Typography>
    </Box>
  );
};

//Function to minimize the length of the project description
const DescriptionCell = ({ value }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 100;
  // Function to toggle the description view
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const displayText =
    isExpanded || !value || value.length <= maxLength
      ? value
      : `${value.substring(0, maxLength)}...`;
  return (
    <Box sx={{ textAlign: "justify", fontSize: "12px" }}>
      <Typography sx={{ fontSize: "12px" }}>
        {displayText || "No Description for the selected project"}
        {value && value.length > maxLength && (
          <Button
            onClick={handleToggle}
            sx={{ textTransform: "none", p: 0, fontSize: "12px" }}
          >
            {isExpanded ? "Show Less" : "Show More"}
          </Button>
        )}
      </Typography>
    </Box>
  );
};

// Styles
const styles = {
  chartsContainer: {
    width: "100%",
    borderRadius: "10px",
    // border: "1px solid grey",
    boxShadow: 6,
    px: 2.5,
    pt: 1,
  },
  topContainer: {
    textAlign: "start",
    display: "flex",
    justifyContent: "center",
    mb: 3,
  },
  searchSelectTextField: {
    width: "215px",
    backgroundColor: "white",
  },
  stfNContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  selectTxtStyle: {
    width: "190px",
  },
  // --------------------Bharathan-----------------------------
  box: {
    height: "350px",
  },
  paper: {
    boxShadow: 3,
    height: "350px",
  },
  filter: {
    position: "relative",
    top: "4px",
    right: "8px",
  },
  mainBox: {
    pt: 0,
    boxShadow: 3,
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    height: "100vh",
    pr: 2,
    pl: 2,
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "400px",
    maxHeight: "500px",
    padding: 2,
    position: "relative",
  },
  closeButton: {
    color: "red",
    position: "absolute",
    top: "4px",
    right: "8px",
  },
  divider: {
    border: "1px solid lightgrey",
    margin: "8px 0",
    marginY: 1,
  },
  paperDrawer: {
    marginBottom: 2,
    padding: 2,
    boxShadow: 10,
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
  },
  drawerWrap: {
    marginTop: 2,
  },

  dataTypographyKey: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "110px",
  },
  dataTypographyValue: {
    marginLeft: 1,
    fontSize: "14px",
    textAlign: "left",
  },
  dataBox: {
    display: "flex",
    flexDirection: "row",

    alignItems: "center",
    flexWrap: "wrap",
    gap: 1,
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "100%",
  },
  card: {
    boxShadow: 3,
    width: "100%",
    height: "auto",
    marginBottom: 1,
    padding: 2,
  },
  cardContent: {
    display: "flex",
    height: "40px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dataTypography: {
    fontWeight: "bold",
    fontSize: "13px",
    textAlign: "left",
    minWidth: "120px",
  },
  dataTypographyCardKey: {
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    minWidth: "50px",
  },
  dataTypographyCardValue: {
    fontSize: "15px",
    textAlign: "left",
    minWidth: "50px",
  },
  btnsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    mb: 1,
    gap: 1,
  },
  cardDrawer: { margin: 1, padding: 2, boxShadow: 3 },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px",
  },
};
