import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import appTheme from "../../assets/AppTheme/appTheme";
import {
  ChipsTextField,
  CustomAddDialog,
  CustomTextFieldN,
  CustomTextFieldSP,
  MultiSelectTextFieldNVs,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";
import { projectConfigAPI } from "../../redux/actions";

function VehicleCompnents({
  selectedVariant,
  selectedVariantDetails,
  selectedProjectDetails,
}) {
  const dispatch = useDispatch();
  const [openAddVehicleSystemsDialog, setOpenAddVehicleSystemsDialog] =
    useState(false);
  const [variantFilteredDetails, setVariantFilteredDetails] = useState([]);

  const toggleDialog = (newOpen) => () => {
    setOpenAddVehicleSystemsDialog(newOpen);
  };

  const handleAddVehicleSystemsClick = () => {
    toggleDialog(true)();
  };

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.get_variant_details,
        params: {
          project_id: selectedProjectDetails?.project_id,
          is_complete_details_required: true,
        },
      })
    );
  }, [selectedProjectDetails]);
  useEffect(() => {
    const filteredVariant = selectedVariantDetails?.find(
      (data) => data?.variant_id === selectedVariant?.value
    );
    console.log("----???filteredVariant???---", filteredVariant);
    setVariantFilteredDetails(filteredVariant);
  }, [selectedVariantDetails, selectedVariant]);

  console.log("variantFilteredDetails");
  return (
    <Box sx={styles.eeCompMainContainer}>
      <Box>
        {/* Vehicle Component card */}
        <Card sx={styles.eeCompCard}>
          <CardHeader
            sx={styles.eeCompCardHeader}
            title={
              <>
                <Typography
                  textAlign={"start"}
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {/* {heading} */}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    textAlign={"start"}
                    fontSize="13px"
                    alignContent={"center"}
                    color="textSecondary"
                  >
                    {"Vehicle Components"}
                  </Typography>
                </Box>
              </>
            }
            action={
              <Box
                sx={{
                  p: 1.5,
                }}
              >
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  startIcon={<AddIcon />}
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={handleAddVehicleSystemsClick}
                >
                  Add
                </Button>
              </Box>
            }
          />

          <CardContent sx={styles.eeCompCardContent}>
            {variantFilteredDetails?.vehicle_components?.length > 0 ? (
              variantFilteredDetails?.vehicle_components?.map((data, id) => (
                <Box
                  // key={item.ee_id} // Use a unique identifier for the key
                  // onClick={() => handleSelectedEEComponent(item)}
                  sx={{
                    ...styles.eeCompsList,
                    border:
                      // selectedEEComponentDetails &&
                      // selectedEEComponentDetails?.ee_id == item?.ee_id
                      //   ? "2px solid blue" :
                      "1px solid lightgrey",
                  }}
                >
                  <>
                    <Box sx={styles.eeCompsListItemContainer}>
                      <Typography variant="body2">
                        <strong>Component Name:</strong> {data?.component}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Assembly No:</strong>
                        {data?.assembly_number}
                      </Typography>

                      <Typography variant="body2">
                        <strong>Summary:</strong>
                        {data?.summary}
                      </Typography>
                    </Box>
                  </>
                </Box>
              ))
            ) : (
              <Typography variant="body1" sx={styles.noDataAvailable}>
                No data available
              </Typography>
            )}
          </CardContent>
        </Card>

        <AddVehicleSystems
          selectedVariantDetails={selectedVariantDetails}
          selectedProjectDetails={selectedProjectDetails}
          open={openAddVehicleSystemsDialog}
          toggleDialog={toggleDialog}
          selectedVariant={selectedVariant}
        />
      </Box>
    </Box>
  );
}

export default VehicleCompnents;

const AddVehicleSystems = ({
  open,
  toggleDialog,
  selectedProjectDetails,
  selectedVariantDetails,
  selectedVariant,
}) => {
  const dispatch = useDispatch();

  const [suppliers, setSuppliers] = useState([]);
  const [componentInput, setComponentInput] = useState("");
  const [componentsError, setComponentsError] = useState(false);
  const [assemblyNo, setAssemblyNo] = useState("");
  const [assemblyNoError, setAssemblyNoError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");

  const [selectedVariants, setSelectedVariants] = useState([]);
  const [variantOptionList, setVariantOptionList] = useState([]);

  console.log("selectedVariants", selectedVariants);

  useEffect(() => {
    console.log("selectedVariantDetails", selectedVariantDetails);
    if (selectedVariantDetails?.length > 0) {
      const filteredVariantsDetails = selectedVariantDetails?.map((el) => {
        return {
          id: el?.variant_id?.toString(),
          name: el?.name,
        };
      });

      console.log("-----filteredVariantsDetails", filteredVariantsDetails);

      setVariantOptionList(filteredVariantsDetails);
    }
  }, [selectedVariantDetails]);

  const handleClose = () => {
    setSuppliers([]);
    setComponentInput("");
    toggleDialog(false)();
  };

  const handleClickAdd = () => {
    const reqObj = {
      project_id: selectedProjectDetails?.project_id,
      variant_id: selectedVariant?.value,
      component: componentInput,
      assembly_number: assemblyNo,
      summary: summary,
      suppliers: suppliers,
      variant_ids: selectedVariants,
    };

    dispatch(
      HandleApiActions({
        ...projectConfigAPI.update_vehicle_components,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...projectConfigAPI.get_variant_details,
          params: {
            project_id: selectedProjectDetails?.project_id,
            is_complete_details_required: true,
          },
        })
      );
    });

    // Reset form fields
    setSuppliers([]);
    setComponentInput("");
    toggleDialog(false)();
  };

  return (
    <>
      <CustomAddDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Add Vehicle Components"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextFieldN
              size={"small"}
              label={"Assembly No*"}
              placeholder={"Assembly No*"}
              type={"text"}
              fldType={"textR"}
              value={assemblyNo}
              setValue={setAssemblyNo}
              valueError={assemblyNoError}
              setValueError={setAssemblyNoError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Component Name*"}
              placeholder={"Component Name*"}
              type={"text"}
              fldType={"text"}
              value={componentInput}
              setValue={setComponentInput}
              valueError={componentsError}
              setValueError={setComponentsError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />

            <CustomTextFieldN
              size={"small"}
              label={"Summary"}
              placeholder={"Summary"}
              type={"text"}
              fldType={"textO"}
              value={summary}
              setValue={setSummary}
              valueError={summaryError}
              setValueError={setSummaryError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            <MultiSelectTextFieldNVs
              required
              mutiple
              size={"small"}
              label={"Variants*"}
              optionsList={variantOptionList}
              selectedValues={selectedVariants}
              setSelectedValues={setSelectedVariants}
              sltFldStyle={styles.customTextFieldN}
            />
            <ChipsTextField
              size={"small"}
              label={"Suppliers*"}
              placeholder={"Suppliers*"}
              variant={"standard"}
              chips={suppliers}
              setChips={setSuppliers}
              txtFldStyle={styles.chipsTextField}
            />
          </Stack>
        }
        onConfirm={handleClickAdd}
        //onDisabled={components.length > 0 ? false : true}
      />
    </>
  );
};

// Styles
const styles = {
  eeCompMainContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-evenly",
  },
  eeCompCard: {
    mt: 1.3,
    mx: 2,
    width: "370px",
    boxShadow: 12,
  },
  eeCompCardHeader: {
    py: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  eeCompCardContent: {
    height: "391px",
    overflowY: "auto",
  },
  eeCompCardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  eeCompsList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1.5,
    pl: 1.4,
    pr: 1,
    backgroundColor: "background.paper",
    borderRadius: 1,
    boxShadow: 4,
    cursor: "pointer",
    "&:hover": {
      // backgroundColor: "lightgrey",
    },
  },
  eeCompsListItemContainer: {
    flexGrow: 1,
    textAlign: "start",
    my: 1,
  },
  noDataAvailable: {
    textAlign: "center",
    mt: 2,
  },
  txtStyle: {
    width: "270px",
  },
  txtStyleR: {
    width: "380px",
  },
  edRpIconsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  customTextFieldN: {
    width: "270px",
  },
};
