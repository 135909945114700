/**
 * @author Tahir Shaik - tahir@bookwater.com - +91-tahir@au79consulting.com
 * @date 11-06-2024
 * @description This is the Virtual Type Approval Page for the Application
 */

//Importing the required components/functions from the libraries
import {
  Box,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Links from "./Links/Links";
import Intersections from "./Intersections/Intersections";
import Tickets from "./Tickets/Tickets";
import {
  SearchSelectTextField,
  SearchSelectTextFieldN,
} from "../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import labCar from "../../redux/actions/labCar";
import typeApprovalAPI from "../../redux/actions/typeApprovalAPI";
import InfoButton from "../../components/infoButton/InfoButton";
import IntersDelTicket from "../../pages/vta/Intersections/IntersDelTicket";
import { useLocation, useNavigate } from "react-router-dom";
import { projectConfigAPI } from "../../redux/actions";
import selectProjectAction from "../../redux/actions/selectProject";
import selectVariantAction from "../../redux/actions/selectVariant";

function Vta() {
  const dispatch = useDispatch();
  const location = useLocation();
  /* -------------- States -------------- */
  /* Project states */
  const [projectOptionsList, setProjectOptionsList] = useState([]);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  /* Variant states */
  const [variantOptionsList, setVariantOptionsList] = useState([]);
  const [selectedVariantDetails, setSelectedVariantDetails] = useState(null);
  /* EE states */
  const [projectEECount, setProjectEECount] = useState(0);
  /* Tab states */
  const [tabValue, setTabValue] = useState(0);
  const [linkTab, setLinkTab] = useState(false);
  /* Intersection states */
  const [showIntersDelTicket, setShowIntersDelTicket] = useState(false);
  const [intersectionDetailsNew, setIntersectionDetailsNew] = useState([]);
  const [isSWMToLegalIntersection, setIsSWMToLegalIntersection] =
    useState(true);
  /* -------------- useSelectors -------------- */
  const { projectDetails, selectedProject, selectedVariant } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );
  const { variantInfo: variantDetails } = useSelector(
    (state) => state.variantReducer,
    shallowEqual
  );

  /* -------------- Functions -------------- */
  /* Function to handle Tab Change */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // if (newValue === 0 || newValue === 2) {
    //   setLinkTab(false);
    //   setIntersectionDetailsNew([]);
    // }
  };
  /* Handler to show IntersDelTicket */
  const handleSaveDraft = () => {
    setShowIntersDelTicket(true);
  };

  /* -------------- UseEffects -------------- */
  useEffect(() => {
    /* Get project details */
    dispatch(HandleApiActions({ ...labCar.get_project_details }));
  }, []);
  /* Get project options list & select first project by default, if not selected */
  useEffect(() => {
    /* Get project options list */
    let filteredProjectOptionsList = projectDetails
      ?.map((el) => {
        return {
          value: el?.project_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
    setProjectOptionsList(filteredProjectOptionsList);

    /* Store first project in the redux store */
    if (!(selectedProject?.value && selectedProject?.label)) {
      dispatch(selectProjectAction(filteredProjectOptionsList?.[0]));
    }
  }, [projectDetails]);
  /* Get selected project details */
  useEffect(() => {
    if (selectedProject) {
      let filteredSelectedProjectDetails = projectDetails?.find(
        (el) => el?.project_id === selectedProject?.value
      );
      setSelectedProjectDetails(filteredSelectedProjectDetails);
    }
  }, [selectedProject]);
  /* Get variant details, when the project is changed */
  useEffect(() => {
    if (selectedProject) {
      dispatch(
        HandleApiActions({
          ...projectConfigAPI.get_variant_details,
          params: {
            project_id: selectedProject?.value,
            is_complete_details_required: true,
          },
        })
      );
    }
  }, [selectedProject]);
  /* Get variant options list & select first variant by default, if not selected */
  useEffect(() => {
    /* Get variant options list */
    let filteredVariantOptionsList = variantDetails
      ?.map((el) => {
        return {
          value: el?.variant_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
    setVariantOptionsList(filteredVariantOptionsList);
    /* Store first variant in the redux store */
    if (!(selectedVariant?.value && selectedVariant?.label)) {
      dispatch(selectVariantAction(filteredVariantOptionsList?.[0]));
    } else if (
      selectedVariant?.value &&
      selectedVariant?.label &&
      !filteredVariantOptionsList?.length
    ) {
      dispatch(selectVariantAction(null));
    }
  }, [variantDetails]);
  /* Get selected variant details */
  useEffect(() => {
    let filteredSelectedVariantDetails = variantDetails?.find(
      (el) => el?.variant_id === selectedVariant?.value
    );
    setSelectedVariantDetails(filteredSelectedVariantDetails);
  }, [selectedVariant]);

  return (
    <Box>
      <Box sx={styles.box1}>
        <Box sx={styles.topContainer}>
          <Box sx={styles.searchContainer}>
            <SearchSelectTextFieldN
              size={"small"}
              label={"Search Project"}
              placeholder={"Search Project"}
              noOptionsText={"No projects found"}
              optionsList={projectOptionsList}
              selectedValue={selectedProject}
              handleValueChange={(event, value) =>
                // Store the selected project in the redux store
                dispatch(selectProjectAction(value))
              }
              txtFldStyle={styles.searchSelectTextField}
            />
          </Box>
          <Box sx={styles.searchContainer}>
            <SearchSelectTextFieldN
              size={"small"}
              label={"Search Variant"}
              placeholder={"Search Variant"}
              noOptionsText={"No variants found"}
              optionsList={variantOptionsList}
              selectedValue={selectedVariant}
              handleValueChange={(event, value) =>
                // Store the selected variant in the redux store
                dispatch(selectVariantAction(value))
              }
              txtFldStyle={styles.searchSelectTextField}
            />
          </Box>
        </Box>

        <Grid container my={3}>
          <Grid xs={6}>
            <Paper elevation={6} sx={styles.prjDetailsContainer}>
              <Typography
                sx={{ fontSize: "18px", fontWeight: "bold", pt: 0.4 }}
              >
                Project Details
              </Typography>
              <Divider sx={{ mt: 0.4, mb: 1 }} />
              <Grid container>
                {/* Name Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Name: </span>
                    </Typography>
                    <Typography sx={{ textAlign: "start" }} variant="subtitle1">
                      {selectedProjectDetails?.name || "-"}
                    </Typography>
                  </Box>
                </Grid>

                {/* Architecture Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Architecture: </span>
                    </Typography>
                    <Typography sx={{ textAlign: "start" }} variant="subtitle1">
                      {selectedProjectDetails?.architecture_name || "-"}
                    </Typography>
                  </Box>
                </Grid>

                {/* Trade Mark Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Trade Mark: </span>
                    </Typography>
                    <Typography sx={{ textAlign: "start" }} variant="subtitle1">
                      {selectedProjectDetails?.trade_mark || "-"}
                    </Typography>
                  </Box>
                </Grid>

                {/* Vehicle Platform Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Vehicle Platform: </span>
                    </Typography>
                    <Typography sx={{ textAlign: "start" }} variant="subtitle1">
                      {selectedProjectDetails?.vehicle_platform || "-"}
                    </Typography>
                  </Box>
                </Grid>

                {/* Zones Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Zones: </span>
                    </Typography>
                    <Typography sx={{ textAlign: "start" }} variant="subtitle1">
                      {selectedProjectDetails?.is_zonal
                        ? selectedProjectDetails?.project_zones?.length
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid xs={6}>
            <Paper
              elevation={6}
              sx={styles.prjDetailsContainer}
              key={selectedVariantDetails?.id}
            >
              <Typography
                sx={{ fontSize: "18px", fontWeight: "bold", pt: 0.4 }}
              >
                Variant Details
              </Typography>
              <Divider sx={{ mt: 0.4, mb: 1 }} />
              <Grid container>
                {/* Name Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Name: </span>
                    </Typography>
                    <Typography sx={{ textAlign: "start" }} variant="subtitle1">
                      {selectedVariantDetails?.name || "-"}
                    </Typography>
                  </Box>
                </Grid>

                {/* Power Train Class Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Power Train Class: </span>
                    </Typography>
                    <Typography sx={{ textAlign: "start" }} variant="subtitle1">
                      {selectedVariantDetails?.powertrain_class_details?.[0]
                        ?.name || "-"}
                    </Typography>
                  </Box>
                </Grid>

                {/* Vehicle Status Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Vehicle Status: </span>
                    </Typography>
                    <Typography sx={{ textAlign: "start" }} variant="subtitle1">
                      {selectedVariantDetails?.vehicle_status || "-"}
                    </Typography>
                  </Box>
                </Grid>

                {/* Delivery Market Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Delivery Market: </span>
                    </Typography>
                    <Typography sx={{ textAlign: "start" }} variant="subtitle1">
                      {selectedVariantDetails?.delivery_market || "-"}
                    </Typography>
                  </Box>
                </Grid>

                {/* Vehicle Components Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Vehicle Components: </span>
                    </Typography>
                    <Typography sx={{ textAlign: "start" }} variant="subtitle1">
                      {selectedVariantDetails?.vehicle_components?.length ||
                        "-"}
                    </Typography>
                  </Box>
                </Grid>

                {/* EE Components Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>EE Components: </span>
                    </Typography>
                    <Typography sx={{ textAlign: "start" }} variant="subtitle1">
                      {selectedVariantDetails?.ee_ids?.length || "-"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={styles.box2}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab
              sx={{
                "&.Mui-selected": {
                  fontWeight: 600,
                  color: "black",
                },
              }}
              iconPosition="start"
              label="Link"
              {...a11yProps(0)}
            />

            <Tab
              sx={{
                "&.Mui-selected": {
                  fontWeight: 600,

                  color: "black",
                },
              }}
              // disabled={linkTab ? false : true}
              iconPosition="start"
              label="Intersections"
              {...a11yProps(1)}
            />
            <Tab
              sx={{
                "&.Mui-selected": {
                  fontWeight: 600,

                  color: "black",
                },
              }}
              label="Tickets"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>

        <Box>
          <CustomTabPanel value={tabValue} index={0}>
            <Links
              setIntersectionDetailsNew={setIntersectionDetailsNew}
              setIsSWMToLegalIntersection={setIsSWMToLegalIntersection}
              setSelectedProject={() => {}}
              selectedProjectIsZonal={selectedProjectDetails?.is_zonal}
              selectedProjectZonesList={selectedProjectDetails?.project_zones}
              projectOptionsList={projectOptionsList}
              selectedProject={selectedProject}
              selectedProjectId={selectedProjectDetails?.project_id}
              selectedProjectDetails={selectedProjectDetails}
              selectedSingleVariantDetails={selectedVariantDetails}
              setTabValue={setTabValue}
              setLinkTab={setLinkTab}
              tabValue={tabValue}
              setShowIntersDelTicket={setShowIntersDelTicket}
            />
          </CustomTabPanel>

          <CustomTabPanel value={tabValue} index={1}>
            {intersectionDetailsNew && intersectionDetailsNew.length > 0 ? (
              showIntersDelTicket ? (
                <IntersDelTicket
                  projectOptionsList={projectOptionsList}
                  selectedProject={selectedProject}
                  setSelectedProject={() => {}}
                  selectedProjectId={selectedProjectDetails?.project_id}
                  selectedProjectDetails={selectedProjectDetails}
                  selectedSingleVariantDetails={selectedVariantDetails}
                  setTabValue={setTabValue}
                />
              ) : (
                <Intersections
                  intersectionDetailsList={intersectionDetailsNew}
                  setIntersectionDetailsList={setIntersectionDetailsNew}
                  isSWMToLegalIntersection={isSWMToLegalIntersection}
                  handleTabChange={handleTabChange}
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  handleSaveDraft={handleSaveDraft}
                />
              )
            ) : (
              <IntersDelTicket
                projectOptionsList={projectOptionsList}
                selectedProject={selectedProject}
                setSelectedProject={() => {}}
                selectedProjectId={selectedProjectDetails?.project_id}
                selectedProjectDetails={selectedProjectDetails}
                selectedSingleVariantDetails={selectedVariantDetails}
              />
            )}
          </CustomTabPanel>

          <CustomTabPanel value={tabValue} index={2}>
            <Tickets
              projectOptionsList={projectOptionsList}
              selectedProject={selectedProject}
              setSelectedProject={() => {}}
              selectedProjectId={selectedProjectDetails?.project_id}
              selectedProjectDetails={selectedProjectDetails}
              selectedSingleVariantDetails={selectedVariantDetails}
            />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
}

export default Vta;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//Styles for the Page
const styles = {
  box1: { width: "100%", position: "relative" },
  box2: {
    position: "sticky",
    top: 0,
    borderBottom: 1,
    borderColor: "divider",
    backgroundColor: "white",
    zIndex: 1000,
    borderTop: "1px solid lightgrey",
  },
  searchContainer: {
    textAlign: "start",
    mt: { xs: 2, md: 0 },
  },
  searchSelectTextField: {
    width: "215px",
    backgroundColor: "white",
  },
  topContainer: {
    mt: 1,
    mx: 2,
    flexDirection: { xs: "column", md: "row" },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: { xs: 2, md: 4 },
  },
  prjDetailsContainer: {
    px: 2,
    // py: 1,
    mx: 1,
    height: "100%",
  },
  prjDetailsTxt: {
    textAlign: "start",
    alignContent: "center",
  },
  txtBold: {
    fontWeight: "bold",
  },
};
